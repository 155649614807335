<template>
  <div>
    <v-file-input
      dense
      outlined
      :label="`Upload ${labelText?labelText:'Image'}`"
      v-model="imageFile"
      accept="image/*"
      @change="handleImageUpload"
    >
    </v-file-input>
  </div>
</template>

<script>
import imageCompression from "browser-image-compression";
export default {
    name: "ImageUploadComponent",
    props:["labelText"],
    data() {
        return {
        imageFile: null,
        };
    },
    methods: {
        async handleImageUpload(file) {
        if (!file) return;
        // Restrict size to 1024 KB
        const maxSizeKB = 1024;
        if (file.size > maxSizeKB * 1024) {
            alert(`File size exceeds ${maxSizeKB} KB limit.`);
            this.imageFile = null;
            return;
        }
        try {
            // Compression options
            const options = {
            maxSizeMB: 1, // Limit the size to around 1 MB
            maxWidthOrHeight: 1024, // Resize if necessary
            useWebWorker: true, // Enable multithreading
            };
            // Compress the image
            const compressedFile = await imageCompression(file, options);
            // console.log("Original File Size:", (file.size / 1024).toFixed(2), "KB");
            // console.log("Compressed File Size:",(compressedFile.size / 1024).toFixed(2),"KB");

            this.imageFile = compressedFile;

            // Emit the compressed file to the parent component
            this.$emit("imageUploaded", compressedFile);

            // Optionally, log or process the Base64 string
            // const base64Image = await this.convertToBase64(compressedFile);
            // console.log("Base64 Image:", base64Image);
        } catch (error) {
            console.error("Error during image compression:", error);
            alert("Failed to compress the image.");
        }
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
