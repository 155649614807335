var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),(!_vm.dependenciesLoaded)?_c('span',[_c('LoadingLinear',{attrs:{"text":"Checklists are loading..."}})],1):_c('span',[_c('v-row',[_c('v-col',[_c('div',{style:({ display: 'flex', alignItems: 'center' })},[_c('h2',{style:({ color: _vm.$store.getters.getColorPalette().accentCode })},[_vm._v(" Ongoing Checklist ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"background-color":_vm.$store.getters.getColorPalette().background1ColorCode,"color":_vm.$store.getters.getColorPalette().accentCode,"dark":_vm.$store.getters.getColorPalette().isDark,"outlined":"","small":"","text":""},on:{"click":_vm.init}},[_c('v-icon',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().btnborderColorCode}},[_vm._v("mdi-refresh")])],1)],1)]),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(_vm.checklistTable && _vm.checklistTable.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{style:({
            border:
              '1px solid' +
              _vm.$store.getters.getColorPalette().panelBorderColorCode,
          }),attrs:{"headers":_vm.headers,"items":_vm.checklistTable,"item-key":"index","search":_vm.search,"single-expand":true,"show-expand":"","expanded":_vm.expanded,"loading":_vm.loading,"loading-text":"Loading... Please wait","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().checklists))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('div',{style:(_vm.getStatusStyle(item.status))},[_vm._v(" "+_vm._s(_vm.statusNameMap[item.status])+" ")])]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [(item.stage === 'maker')?_c('td',{attrs:{"colspan":headers.length}},[_c('ChecklistMakerForm',{attrs:{"checklist":item},on:{"submitted":_vm.init}})],1):(
                item.stage === 'checker' || item.stage === 'approver'
              )?_c('td',{attrs:{"colspan":headers.length}},[_c('ChecklistFormDetails',{attrs:{"checklist":item,"readOnly":false},on:{"submitted":_vm.init,"refillChecklist":_vm.init}})],1):_vm._e()]}}],null,false,2642648744)})],1):(!_vm.loading)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{style:([
            {
              padding: '10px',
              display: 'flex',
              'justify-content': 'center',
              color: _vm.$store.getters.getColorPalette().accentCode,
            } ])},[_c('v-icon',{staticClass:"mr-3",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}},[_vm._v("mdi-database-remove-outline")]),_c('h3',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().no_data_available))])],1)]):_vm._e()],1),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(_vm.refillChecklistTable && _vm.refillChecklistTable.length > 0)?_c('v-row',[_c('v-col',[_c('div',{style:({ display: 'flex', alignItems: 'center' })},[_c('h2',{style:({ color: _vm.$store.getters.getColorPalette().accentCode })},[_vm._v(" Checklist For Refill ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"background-color":_vm.$store.getters.getColorPalette().background1ColorCode,"color":_vm.$store.getters.getColorPalette().accentCode,"dark":_vm.$store.getters.getColorPalette().isDark,"outlined":"","small":"","text":""},on:{"click":_vm.getRefillChecklists}},[_c('v-icon',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().btnborderColorCode}},[_vm._v("mdi-refresh")])],1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{style:({
            border:
              '1px solid' +
              _vm.$store.getters.getColorPalette().panelBorderColorCode,
          }),attrs:{"headers":_vm.headers,"items":_vm.refillChecklistTable,"item-key":"index","refill_search":_vm.search,"refill_single-expand":true,"show-expand":"","refill_expanded":_vm.expanded,"loading":_vm.loading,"loading-text":"Loading... Please wait","refill_sort-by":_vm.sortBy,"refill_sort-desc":_vm.sortDesc},on:{"update:refill_expanded":function($event){_vm.expanded=$event},"update:refill_sortBy":function($event){_vm.sortBy=$event},"update:refill_sort-by":function($event){_vm.sortBy=$event},"update:refill_sortDesc":function($event){_vm.sortDesc=$event},"update:refill_sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().checklists))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('div',{style:(_vm.getStatusStyle(item.status))},[_vm._v(" "+_vm._s(_vm.statusNameMap[item.status])+" ")])]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ChecklistMakerForm',{attrs:{"checklist":item,"readOnly":false},on:{"submitted":_vm.init}})],1)]}}],null,false,4126371609)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }