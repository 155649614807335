<template>
  <v-container fluid class="no-padding bordered-container">
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-card
      class="pa-5"
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().background2ColorCode"
      flat
    >
      <div class="d-flex justify-space-between">
        <h3 class="py-4">
          {{ $store.getters.getTextMap().create }}
          {{ $store.getters.getTextMap().log_book }}
        </h3>
        <div>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            @click="$emit('closeForm')"
            class="mr-3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col>
            <v-text-field
              :label="$store.getters.getTextMap().name"
              v-model="form.name"
              dense
              outlined
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              :label="$store.getters.getTextMap().template"
              v-model="form.template_id"
              :items="templates"
              item-text="name"
              item-value="template_id"
              dense
              outlined
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              :rules="[rules.required]"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :label="$store.getters.getTextMap().shifts"
              v-model="form.shift_id"
              :items="shiftOptions"
              item-text="name"
              item-value="shift_id"
              dense
              outlined
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              :rules="[rules.required]"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              :label="
                $store.getters.getTextMap().schedule +
                ' ' +
                $store.getters.getTextMap().type
              "
              v-model="scheduleType"
              :items="scheduleTypes"
              item-text="label"
              item-value="value"
              dense
              outlined
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              :rules="[rules.required]"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              v-if="scheduleType == 'cron'"
              :label="$store.getters.getTextMap().schedule"
              v-model="form.schedule"
              dense
              outlined
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :label="
                $store.getters.getTextMap().closure +
                ' ' +
                $store.getters.getTextMap().duration +
                '(' +
                $store.getters.getTextMap().minutes +
                ')'
              "
              v-model="form.closure_duration"
              dense
              outlined
              :rules="[rules.integ]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              :label="$store.getters.getTextMap().maker"
              v-model="form.maker"
              :items="userGroups"
              item-text="name"
              item-value="group_id"
              dense
              outlined
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              :rules="[rules.required]"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :label="
                $store.getters.getTextMap().power +
                ' ' +
                $store.getters.getTextMap().maker
              "
              v-model="form.power_maker"
              :items="userGroups"
              item-text="name"
              item-value="group_id"
              dense
              outlined
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :label="$store.getters.getTextMap().checker"
              v-model="form.checker"
              :items="userGroups"
              item-text="name"
              item-value="group_id"
              dense
              outlined
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :label="$store.getters.getTextMap().approver"
              v-model="form.approver"
              :items="userGroups"
              item-text="name"
              item-value="group_id"
              dense
              outlined
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :label="$store.getters.getTextMap().issuer"
              v-model="form.issuer"
              :items="userGroups"
              item-text="name"
              item-value="group_id"
              dense
              outlined
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-center pa-8">
            <v-btn
              :color="$store.getters.getColorPalette().submitbtnColor"
              @click="onSubmit"
              class="mr-3"
            >
              {{ $store.getters.getTextMap().submit }}
            </v-btn>
            <v-btn
              :color="$store.getters.getColorPalette().cancelbtnColor"
              :dark="$store.getters.getColorPalette().isDark"
              @click="onReset"
              class="ml-3"
            >
              {{ $store.getters.getTextMap().cancel }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import FormRules from "@/utillities/FormRules";
import InfoAlert from "@/components/InfoAlert";
import axios from "axios";
export default {
  name: "CreateManufacturingLogbook",
  props: ["editLogbookData"],
  components: {
    InfoAlert,
  },
  data() {
    return {
      showDismissibleAlert: false,
      info: "",
      loading: false,
      valid: false,
      rules: FormRules.rules,
      scheduleType: "cron",
      templates: [],
      scheduleTypes: [
        { label: "Cron", value: "cron" },
        { label: "Auto trigger on submit", value: "adhoc" },
        { label: "Manual", value: "manual" },
      ],
      endpoint: "createManufacturingLogbook",
      form: {
        name: null,
        template_id: null,
        schedule: null,
        closure_duration: null,
        maker: null,
        power_maker: null,
        checker: null,
        approver: null,
        issuer: null,
        shift_id: null,
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    userGroups() {
      let op = [];

      op = this.$store.getters.getUserGroups();

      return op;
    },
    shiftOptions() {
      let op = [];

      op = this.$store.getters.getShifts();
      return op;
    },
  },
  methods: {
    init() {
      this.form = {
        name: null,
        template_id: null,
        schedule: null,
        closure_duration: null,
        maker: null,
        power_maker: null,
        checker: null,
        approver: null,
        issuer: null,
        shift_id: null,
      };
      this.scheduleType = "cron";
      if (this.editLogbookData && this.editLogbookData.logbook_id) {
        this.form = this.editLogbookData;
        this.scheduleType =
          this.editLogbookData.schedule === "adhoc" ? "adhoc" : "cron";
        this.form["logbook_id"] = this.editLogbookData.logbook_id;
        this.endpoint = "updateManufacturingLogbook";
      }
      this.getChecklistTemplates();
    },
    getChecklistTemplates() {
      this.loading = true;
      axios
        .get(this.$store.state.api + "manufacturingLogbookTemplates", {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          this.templates = [...response.data.data];
          if (response.data.status === "success") {
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);

          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    onSubmit() {
      this.$refs.form.validate();
      if (this.valid) {
        axios
          .post(this.$store.state.api + this.endpoint, this.form, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              this.$emit("refetchData");
              this.$emit("closeForm");
              this.loading = false;
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
              this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.info = err;
            this.loading = false;
          });
      }
    },
    resetForm() {
      this.form = {
        name: null,
        template_id: null,
        schedule: null,
        closure_duration: null,
        maker: null,
        power_maker: null,
        checker: null,
        approver: null,
        issuer: null,
        shift_id: null,
      };
      this.scheduleType = "cron";
      this.template_id = null;
      this.$emit("closeForm");
    },
    onReset() {
      this.$refs.form.reset();
    },
  },
  watch: {
    editLogbookData: {
      handler(newVal, oldVal) {
        if (!oldVal || newVal.logbook_id !== oldVal.templatlogbook_ide_id) {
          this.init(); // Only reset when the template ID actually changes
        }
      },
      deep: true,
    },
    scheduleType: {
      handler() {
        if (this.scheduleType === "adhoc") {
          this.form.schedule = "adhoc";
        }
      },
      deep: true,
    },
  },
};
</script>
