<template>
  <v-container fluid class="no-padding bordered-container">
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-card
      v-if="!loading"
      class="pa-5"
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().background2ColorCode"
      flat
    >
      <div class="d-flex justify-space-between">
        <h3 class="py-4">
          {{ $store.getters.getTextMap().create }}
          {{ $store.getters.getTextMap().reference }}
          {{ $store.getters.getTextMap().sheet }}
        </h3>
        <div>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            @click="$emit('closeForm')"
            class="mr-3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-select
              v-if="!loading"
              label="Select columns from list"
              placeholder="Select columns from list"
              v-model="list_id"
              :items="lists"
              item-text="label"
              item-value="value"
              outlined
              dense
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="mr-2">
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <div class="d-flex align-center">
              <v-switch
                small
                inset
                style="transform: scale(0.8)"
                color="success"
                v-model="newTable"
                :label="
                  $store.getters.getTextMap().new +
                  ' ' +
                  $store.getters.getTextMap().table
                "
              ></v-switch>
              <v-text-field
                v-if="!loading && newTable"
                placeholder="Name"
                label="Name"
                v-model="form.table_name"
                :rules="[rules.required]"
                outlined
                dense
              >
              </v-text-field>
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-select
                v-if="!loading && !newTable"
                label="Select existing table"
                placeholder="Select existing table"
                v-model="form.table_name"
                :items="existingSheets"
                item-text="table_name"
                item-value="table_name"
                outlined
                dense
              >
              </v-select>
            </div>
          </v-col>
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-text-field
              v-if="!loading"
              label="Key Value"
              placeholder="Key Value"
              v-model="form.key_value"
              :rules="[rules.required]"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <div
          v-if="
            form &&
            form.meta &&
            Object.keys(form.meta) &&
            Object.keys(form.meta).length > 0
          "
        >
          <v-row
            v-for="(item, index) in Object.keys(form.meta)"
            :key="index"
            no-gutters
          >
            <v-col>
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-text-field
                v-if="!loading"
                :label="item"
                :placeholder="item"
                v-model="form.meta[item]"
                :rules="[rules.required]"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12" class="d-flex justify-center pa-8">
            <v-btn
              :color="$store.getters.getColorPalette().submitbtnColor"
              @click="onSubmit"
              class="mr-3"
            >
              {{ $store.getters.getTextMap().add }}
            </v-btn>
            <v-btn
              :color="$store.getters.getColorPalette().cancelbtnColor"
              :dark="$store.getters.getColorPalette().isDark"
              @click="$emit('closeForm')"
              class="ml-3"
            >
              {{ $store.getters.getTextMap().cancel }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import FormRules from "@/utillities/FormRules";
import InfoAlert from "@/components/InfoAlert";
import axios from "axios";
export default {
  name: "CreateManufacturingLogbookreferenceSheet",
  props: ["editData"],
  components: {
    InfoAlert,
  },
  data() {
    return {
      showDismissibleAlert: false,
      info: "",
      loading: false,
      valid: false,
      rules: FormRules.rules,
      endpoint: "createManufacturingLogbookRefSheet",
      list_id: null,
      existingSheets: [],
      newTable: false,
      form: {
        table_name: null,
        key_value: null,
        meta: {},
      },
    };
  },
  computed: {
    lists() {
      let op = [];
      for (let i of this.$store.state.lists) {
        op.push({ label: i.name, value: i.list_id });
      }
      return op;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getExistingReferenceSheets();
      this.form = {
        table_name: null,
        key_value: null,
        meta: {},
      };
      if (
        this.editData &&
        this.editData.table_name &&
        this.editData.key_value
      ) {
        this.form.table_name = this.editData.table_name;
        this.form.key_value = this.editData.key_value;
        this.createMetaFormForEdit();
      }
      this.loading = false;
    },
    getExistingReferenceSheets() {
      axios
        .get(this.$store.state.api + "manufacturingLogbookRefSheets", {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.existingSheets = [...response.data.data];
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    getListTuple(list_id) {
      this.loading = true;
      let op = [];
      let lists = this.$store.getters.getListDataById(list_id);
      if (lists && lists.length > 0) {
        for (let i of lists) {
          op.push({ label: i.tag, value: i.value });
        }
      }
      this.loading = false;
      return op;
    },
    createMetaForm() {
      this.loading = true;
      this.form.meta = {};
      let tuple = this.getListTuple(this.list_id);
      for (let i of tuple) {
        this.form.meta[i.value] = "";
      }
      this.loading = false;
    },
    createMetaFormForEdit() {
      this.loading = true;
      this.list_id = null;
      this.form.meta = {};
      for (let i of Object.keys(this.editData.meta)) {
        this.form.meta[i] = this.editData.meta[i];
      }
      this.loading = false;
    },
    onSubmit() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        axios
          .post(this.$store.state.api + this.endpoint, this.form, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              this.$emit("refetchData");
              this.info = "Data added successfully";
              this.showDismissibleAlert = true;
              this.loading = false;
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
              this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.info = err;
            this.loading = false;
          });
      }
    },
  },
  watch: {
    list_id: {
      handler() {
        this.createMetaForm();
      },
      deep: true,
    },
    editData: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
