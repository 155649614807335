<template>
  <v-container fluid> Downloading.... </v-container>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
export default {
  name: "ChecklistDataPDFTemplate",
  props: ["checklistFormDetails", "checklist"],
  data() {
    return {
      statusNameMap: {
        in_process: "In Process",
        rejected: "Rejected",
        completed: "Completed",
        refill: "Refill",
      },
      pageSizeConfig: {
        a4: {
          width: 210,
          height: 297,
          centerX: 105,
          rightX: 160,
          logoSize: 30,
        },
        a3: {
          width: 297,
          height: 420,
          centerX: 148,
          rightX: 220,
          logoSize: 30,
        },
        a2: {
          width: 420,
          height: 594,
          centerX: 210,
          rightX: 310,
          logoSize: 30,
        },
      },
    };
  },
  mounted() {
    this.downloadPDF();
  },
  methods: {
    getPageSize(length) {
      return length <= 5 ? "a4" : length <= 8 ? "a3" : "a2";
    },

    async downloadPDF() {
      let pageSize = this.getPageSize(
        this.checklist?.table_json?.headers?.length || 0
      );
      const config = this.pageSizeConfig[pageSize];
      const doc = new jsPDF("p", "mm", pageSize);

      // ** Add Page Border **
      doc.setLineWidth(0.5);
      doc.rect(5, 5, config.width - 10, config.height - 10);

      // ** Load Logo **
      let logo = this.$store.state.organisation.logo;
      if (logo) {
        try {
          const img = await this.loadImage(logo);
          doc.addImage(
            img,
            "JPEG",
            10,
            10,
            config.logoSize,
            config.logoSize * 0.6
          );
        } catch (error) {
          console.error("Error loading logo:", error);
        }
      }

      // ** Header Section with Vertical Dividers **
      doc.setFont("calibri", "bold");
      doc.setFontSize(14);
      doc.setTextColor(0, 51, 102);
      doc.text(this.$store.state.organisation.name, config.centerX, 20, {
        align: "center",
      });
      doc.setFont("calibri");
      doc.setFontSize(10);
      doc.text(
        this.$store.state.organisation.address
          ? this.$store.state.organisation.address
          : "",
        config.centerX,
        25,
        {
          align: "center",
        }
      );

      doc.line(5, 34, config.width - 5, 34);
      doc.setFont("calibri", "bold");
      doc.setFontSize(10);
      doc.setTextColor(0);
      let checklistLines = doc.splitTextToSize(
        this.checklist.name,
        config.width - 40
      );
      let footerY = config.height - 20;
      doc.text(checklistLines, config.centerX, 39, { align: "center" });
      doc.line(5, footerY - 6, config.width - 5, footerY - 6);

      // ** Second Row with Separators **
      doc.setFontSize(9);
      doc.text(`Submitted By: ${this.checklist.submitted_by}`, 10, footerY);
      doc.text(
        `Approved By: ${this.checklist.approved_by}`,
        config.rightX,
        footerY
      );

      // Separator Line
      doc.line(5, footerY + 4, config.width - 5, footerY + 4);

      // ** Footer Function **
      const addFooter = (pageNo) => {
        const footerY = config.height - 10;
        doc.setFont("calibri", "normal");
        doc.setFontSize(8);
        doc.setTextColor(100);

        // Organization Footer
        doc.text(
          "This report was digitally generated on " +
            moment().format("DD-MM-YYYY HH:mm"),
          config.centerX,
          footerY,
          {
            align: "center",
          }
        );
        // Page Number
        doc.text(`Page ${pageNo}`, config.width - 15, footerY);
      };

      // Vertical separator lines for logo, org name, and doc details
      let sectionX = config.width / 3;
      doc.setLineWidth(0.5);
      doc.line(sectionX, 5, sectionX, 34);
      doc.line(sectionX * 2, 5, sectionX * 2, 34);

      // ** Document Details (Aligned to the Right) **
      doc.setFont("calibri", "normal");
      doc.setFontSize(8);
      let rightStartX = config.rightX;
      let rightStartY = 12;
      doc.setTextColor(80);
      doc.text(
        `Doc No: ${
          this.checklist && this.checklist.doc_no ? this.checklist.doc_no : "-"
        }`,
        rightStartX,
        rightStartY
      );
      doc.text(
        `Issue Date: ${
          this.checklist && this.checklist.issue_date
            ? this.checklist.issue_date
            : "-"
        }`,
        rightStartX,
        rightStartY + 4
      );
      doc.text(
        `Issue No: ${
          this.checklist && this.checklist.issue_no
            ? this.checklist.issue_no
            : "-"
        }`,
        rightStartX,
        rightStartY + 9
      );
      doc.text(
        `Rev Date: ${
          this.checklist && this.checklist.version_date
            ? this.checklist.version_date
            : "-"
        }`,
        rightStartX,
        rightStartY + 14
      );
      doc.text(
        `Rev No: ${
          this.checklist && this.checklist.version_no
            ? this.checklist.version_no
            : "-"
        }`,
        rightStartX,
        rightStartY + 19
      );

      // ** Header Separator Line (Extending to Border) **
      doc.line(5, 34, config.width - 5, 34);
      doc.setFont("calibri", "bold");
      doc.setFontSize(10);
      doc.setTextColor(0);
      let checklistNameLines = doc.splitTextToSize(
        this.checklist.name,
        config.width - 40
      );
      doc.text(checklistNameLines, config.centerX, 39, { align: "center" });
      doc.line(5, 43, config.width - 5, 43);

      // ** Second Row with Separators **
      let secondRowY = 48;
      doc.setFontSize(9);
      doc.text(
        `Status: ${this.statusNameMap[this.checklist.status]}`,
        10,
        secondRowY
      );
      // doc.text(
      //   `Submitted By: ${this.checklist.submitted_by}`,
      //   config.centerX,
      //   secondRowY,
      //   { align: "center" }
      // );
      doc.text(
        `Date of report: ${this.checklist.submitted_timestamp}`,
        config.rightX,
        secondRowY
      );

      // Separator Line
      doc.line(5, 52, config.width - 5, 52);

      // ** Checklist Key-Value Pairs **
      let yOffset = 70;
      doc.setFont("calibri", "normal");
      if (this.checklist.table_second) {
        this.checklistFormDetails.forEach((item) => {
          doc.setFontSize(8);
          // doc.text(`${item.key_text}:`, 10, yOffset);
          let keyLines = doc.splitTextToSize(`${item.key_text}:`, 120);
          doc.text(keyLines, 10, yOffset);
          if (item.entry_type === "image") {
            doc.addImage(item.data, "JPEG", rightStartX, yOffset - 5, 20, 20);
          } else if (item.entry_type === "check") {
            doc.text(
              item.data === "true" ? "✔ Yes" : "✘ No",
              rightStartX,
              yOffset
            );
          } else {
            doc.text(item.data, rightStartX, yOffset);
          }
          yOffset += 8 + keyLines.length;
        });
      }

      // ** Table Data **
      yOffset = 50;
      if (this.checklist?.table_json?.headers?.length > 0) {
        let tableStartY = yOffset + 10;
        doc.autoTable({
          startY: tableStartY,
          head: [this.checklist.table_json.headers],
          body: this.checklist.table_json.rows.map((row) =>
            row.map((col) => col.value.data || col.value)
          ),
          theme: "grid",
          styles: { fontSize: 10 },
          headStyles: { fillColor: [0, 100, 200] },
        });
      }

      // ** Checklist Key-Value Pairs (If No Table) **
      if (!this.checklist.table_second) {
        yOffset = 70;
        this.checklistFormDetails.forEach((item) => {
          doc.setFontSize(8);
          // doc.text(`${item.key_text}:`, 10, yOffset);
          let keyLines = doc.splitTextToSize(`${item.key_text}:`, 120);
          doc.text(keyLines, 10, yOffset);
          if (item.entry_type === "image") {
            doc.addImage(item.data, "JPEG", rightStartX, yOffset - 5, 20, 20);
          } else if (item.entry_type === "check") {
            doc.text(
              item.data === "true" ? "✔ Yes" : "✘ No",
              rightStartX,
              yOffset
            );
          } else {
            doc.text(item.data, rightStartX, yOffset);
          }
          yOffset += 8 + keyLines.length;
        });
      }

      // ** Manually Add Footer for Last Page **
      addFooter(doc.internal.getNumberOfPages());

      // ** Download PDF **
      doc.save(
        `${this.checklist.name}_${this.checklist.submitted_timestamp}.pdf`
      );
      this.$emit("success");
    },

    // ** Function to Load Image **
    async loadImage(url) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = url;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL("image/jpeg"));
        };
        img.onerror = reject;
      });
    },
  },
};
</script>
