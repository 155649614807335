<template>
    
    <v-container fluid >
            <v-row no-gutters>
                <v-col>
                    <v-card flat>
                      <v-sheet>
    <v-tabs
      :dark="$store.getters.getColorPalette().isDark"
      :background-color="$store.getters.getColorPalette().backgroundColorCode"
      show-arrows
      v-model="tab"
    >
      <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/batch/overview'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-view-dashboard-outline</v-icon>{{ $store.getters.getTextMap().overview }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/batch/batches'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-abacus</v-icon>{{ $store.getters.getTextMap().batches }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/batch/trackBatch'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-map-marker-path</v-icon>{{ $store.getters.getTextMap().track_batch }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/batch/batchMovement'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-arrow-collapse-right</v-icon>{{ $store.getters.getTextMap().batch_movement }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/batch/productionPlanning'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-notebook</v-icon>{{ $store.getters.getTextMap().production_planner }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/batch/rawMaterials'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-beaker-outline</v-icon>{{ $store.getters.getTextMap().raw_materials }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/batch/SafetyIncident'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-text-box-outline</v-icon>{{ $store.getters.getTextMap().safety_incident }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/batch/SafetyInspection'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-police-badge-outline</v-icon>{{ $store.getters.getTextMap().safety_inspection }}
      </v-tab>
    </router-link>
    
    </v-tabs>
    <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
  </v-sheet>
</v-card>
</v-col>

</v-row>         
<router-view></router-view>
         
        </v-container>
    
</template>

<script>

export default {
    name:'Batches',
    components:{

    },
    data(){
        return {
            show:'',
            tab:null
        }
    },

    mounted() {
      document.title='Manufacturing'
      if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }else{
      this.$router.push('/manufacturing/batch/overview');
    }
    },
}
</script>
<style scoped>

</style>