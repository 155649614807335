<template>
  <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-card flat>
      <v-card-title
        >{{ list.name ? list.name : list.list_id }}
        <v-progress-circular
          indeterminate
          v-if="loading"
          color="primary"
        ></v-progress-circular>
        <v-spacer></v-spacer>
        <span
          v-if="
            $store.state.user &&
            $store.state.settingMode &&
            $store.getters.getAccessByLevels(['engineeraccess'])
          "
        >
          <v-icon
            small
            class="mr-2"
            v-if="
              $store.state.user &&
              $store.state.settingMode &&
              $store.getters.getAccessByLevels(['betaaccess', 'engineeraccess'])
            "
            @click="editMode = !editMode"
          >
            mdi-pencil
          </v-icon>
          <DeleteConfirmation
            v-on:confirm="deleteList()"
            title="Delete Confirmation"
            description="Are you sure you want to delete this?"
          >
            <v-icon small color="red"> mdi-delete </v-icon>
          </DeleteConfirmation>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle>
        <v-row v-if="editMode == true">
          <v-col>
            <CreateDependentList
              :list="list"
              v-on:close="editMode = false"
              v-on:update="$emit('update')"
            />
          </v-col>
        </v-row>
        <v-row v-if="loading">
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row
          v-if="
            $store.state.user &&
            $store.state.settingMode &&
            $store.getters.getAccessByLevels(['engineeraccess'])
          "
        >
          <!-- <v-col cols="1">
            <span
              ><v-btn
                small
                :dark="$store.getters.getColorPalette().isDark"
                @click="addDialog = !addDialog"
                color="blue"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn></span
            >
          </v-col> -->
          <v-col>
            <span
              ><AddTupleToDependentList :list="list" v-if="addDialog"
            /></span>
          </v-col>
        </v-row>
        <v-row v-for="(key, index) in getKeys()" :key="index">
          <v-col>
            <v-card
              outlined
              :dark="$store.getters.getColorPalette().isDark"
              :style="{
                border:
                  '1px solid ' +
                  $store.getters.getColorPalette().panelBorderColorCode,
              }"
              :color="$store.getters.getColorPalette().backgroundColorCode"
            >
              <v-card-title class="d-flex justify-space-between">
                <span>{{ key }}</span>
                <span
                  ><DeleteConfirmation
                    v-on:confirm="deleteQueryKeyFromList(key)"
                    title="Delete Confirmation"
                    description="Are you sure you want to delete this?"
                  >
                    <v-btn v-if="!loading" text outlined small>
                      Delete <v-icon small color="red"> mdi-delete </v-icon>
                    </v-btn>
                  </DeleteConfirmation></span
                >
              </v-card-title>
              <v-divider :dark="$store.getters.getColorPalette().isDark" />
              <v-simple-table height="150px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <strong>{{ $store.getters.getTextMap().tag }}</strong>
                      </th>
                      <th class="text-left">
                        <strong>{{ $store.getters.getTextMap().value }}</strong>
                      </th>
                      <th
                        class="text-left"
                        v-if="
                          $store.state.user &&
                          $store.state.settingMode &&
                          $store.getters.getAccessByLevels(['engineeraccess'])
                        "
                      >
                        <strong>{{
                          $store.getters.getTextMap().actions
                        }}</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in getTuples(key)" :key="item.value">
                      <td>{{ item.tag }}</td>
                      <td>{{ item.value }}</td>
                      <td
                        v-if="
                          $store.state.user &&
                          $store.state.settingMode &&
                          $store.getters.getAccessByLevels(['engineeraccess'])
                        "
                      >
                        <v-icon
                          small
                          color="red"
                          @click="deleteTupleFromList(item, key)"
                          >mdi-delete</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-divider :dark="$store.getters.getColorPalette().isDark" />
            </v-card>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import InfoAlert from "@/components/InfoAlert";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import AddTupleToDependentList from "@/components/crud_components/derived/AddTupleToDependentList";
import CreateDependentList from "../../crud_components/derived/CreateDependentList.vue";
export default {
  name: "DependentList",
  props: ["list"],
  components: {
    InfoAlert,
    AddTupleToDependentList,
    DeleteConfirmation,
    CreateDependentList,
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      info: "",
      showDismissibleAlert: false,
      addDialog: true,
      editMode: false,
    };
  },

  computed: {
    tuples() {
      return this.$store.getters.getDependentListDataByIdAndKey(
        this.list.list_id
      );
    },
  },

  methods: {
    getTuples(queryKey) {
      return this.$store.getters.getDependentListDataByIdAndKey(
        this.list.list_id,
        queryKey
      );
    },
    getKeys() {
      let keys = new Set([]);
      let response = this.$store.getters.getDependentListDataById(
        this.list.list_id
      );
      for (let i of response) {
        keys.add(i.query_key);
      }
      return keys;
    },
    deleteList() {
      axios
        .post(this.$store.state.api + "deleteDependentList", this.list, {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$store.dispatch("refreshDependentLists");
            this.loading = false;
            this.info = "The Dependent List has been deleted";
            this.showDismissibleAlert = true;
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
    },
    deleteQueryKeyFromList(queryKey) {
      let payload = {
        query_key: queryKey,
        list_id: this.list.list_id,
      };
      axios
        .post(this.$store.state.api + "deleteDependentListQueryKey", payload, {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$store.dispatch("refreshDependentListData");
            this.loading = false;
            this.info = "The Dependent List has been deleted";
            this.showDismissibleAlert = true;
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
    },
    deleteTupleFromList(tuple, queryKey) {
      let payload = { ...tuple };
      payload["query_key"] = queryKey;
      axios
        .post(this.$store.state.api + "deleteTupleFromDependentList", tuple, {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$store.dispatch("refreshDependentListData");
            this.loading = false;
            this.info = "The Tuple has been deleted";
            this.showDismissibleAlert = true;
          } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.info = err;
          this.showDismissibleAlert = true;
        });
    },
    cancel() {
      this.showDialog = false;
    },
    confirm() {
      this.showDialog = false;
    },
  },
};
</script>
<style scoped>
.theme--light.v-tabs-items {
  background-color: #263238;
}
</style>
