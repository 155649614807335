<template>
  <v-container fluid class="no-padding">
    <ManufacturingHistoricalLogbookList />
  </v-container>
</template>
<script>
import ManufacturingHistoricalLogbookList from "@/components/lists/manufacturing/logbook/historical/ManufacturingHistoricalLogbookList.vue";
export default {
  name: "ManufacturingLogbookHistoricalPanel",
  components: {
    ManufacturingHistoricalLogbookList,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
