var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"no-padding bordered-container",attrs:{"fluid":""}},[_c('div',{style:({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    })},[_c('div',[_c('v-btn',{attrs:{"color":_vm.$store.getters.getColorPalette().btnColor,"outlined":"","text":"","elevation":"0","small":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().create)+" "+_vm._s(_vm.$store.getters.getTextMap().reference)+" "+_vm._s(_vm.$store.getters.getTextMap().sheet)+" ")]),_c('v-btn',{staticClass:"ml-1",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":"","text":"","outlined":""},on:{"click":function($event){return _vm.handleRefreshData('reference_sheet')}}},[_c('v-icon',{attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().downloadbtnColor,"small":""}},[_vm._v(" mdi-refresh ")])],1)],1)]),(!_vm.loading)?_c('v-row',{attrs:{"no-gutters":""}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_vm._e(),_c('v-col',[_c('ManufacturingLogbookReferenceSheetList',{attrs:{"refetchData":_vm.refetchData},on:{"editRefSheet":_vm.editSheet}})],1)],1):_vm._e(),_c('div',{staticClass:"mb-1",style:({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    })},[_c('div',[_c('v-btn',{attrs:{"color":_vm.$store.getters.getColorPalette().btnColor,"outlined":"","text":"","elevation":"0","small":""},on:{"click":function($event){_vm.templateDialog = true}}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().create)+" "+_vm._s(_vm.$store.getters.getTextMap().log_book)+" "+_vm._s(_vm.$store.getters.getTextMap().template)+" ")]),_c('v-btn',{staticClass:"ml-1",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":"","text":"","outlined":""},on:{"click":function($event){return _vm.handleRefreshData('template')}}},[_c('v-icon',{attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().downloadbtnColor,"small":""}},[_vm._v(" mdi-refresh ")])],1)],1)]),(!_vm.loading)?_c('v-row',{staticClass:"mb-5",attrs:{"no-gutters":""}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_vm._e(),_c('v-col',[_c('ManufacturingLogbookTemplateList',{attrs:{"refetchData":_vm.refetchTemplateData},on:{"editTemplate":_vm.editTemplate}})],1)],1):_vm._e(),_c('div',{staticClass:"mb-1",style:({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    })},[_c('div',[_c('v-btn',{attrs:{"color":_vm.$store.getters.getColorPalette().btnColor,"outlined":"","text":"","elevation":"0","small":""},on:{"click":function($event){_vm.logbookDialog = true}}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().create)+" "+_vm._s(_vm.$store.getters.getTextMap().log_book)+" ")]),_c('v-btn',{staticClass:"ml-1",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":"","text":"","outlined":""},on:{"click":function($event){return _vm.handleRefreshData('logbook')}}},[_c('v-icon',{attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().downloadbtnColor,"small":""}},[_vm._v(" mdi-refresh ")])],1)],1)]),(!_vm.loading)?_c('v-row',{attrs:{"no-gutters":""}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_vm._e(),_c('v-col',[_c('ManufacturingLogbookList',{attrs:{"refetchData":_vm.refetchLogbookData},on:{"editLogbook":_vm.editLogbook}})],1)],1):_vm._e(),_c('v-dialog',{style:({
      border: '2px solid ' + _vm.$store.getters.getColorPalette().accentCode,
    }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().backgroundColorCode,"hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('CreateManufacturingLogbookreferenceSheet',{attrs:{"editData":_vm.editData},on:{"closeForm":_vm.closeDialog,"refetchData":function($event){return _vm.handleRefreshData('reference_sheet')}}})],1),_c('v-dialog',{style:({
      border: '2px solid ' + _vm.$store.getters.getColorPalette().accentCode,
    }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().backgroundColorCode,"hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.templateDialog),callback:function ($$v) {_vm.templateDialog=$$v},expression:"templateDialog"}},[_c('CreateManufacturingLogbookTemplate',{attrs:{"editTemplateData":_vm.editTemplateData},on:{"closeForm":_vm.closeTemplateDialog,"refetchData":function($event){return _vm.handleRefreshData('template')}}})],1),_c('v-dialog',{style:({
      border: '2px solid ' + _vm.$store.getters.getColorPalette().accentCode,
    }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().backgroundColorCode,"hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.logbookDialog),callback:function ($$v) {_vm.logbookDialog=$$v},expression:"logbookDialog"}},[_c('CreateManufacturingLogbook',{attrs:{"editLogbookData":_vm.editLogbookData},on:{"closeForm":_vm.closeLogbookDialog,"refetchData":function($event){return _vm.handleRefreshData('logbook')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }