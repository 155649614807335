<!-- <template>
  <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-row>
      <v-skeleton-loader v-if="loading"></v-skeleton-loader>
      <v-col v-if="!loading">
        <ImageUploadComponent
          @imageUploaded="handleImageUploadConversion"
          :fieldData="fieldData ? fieldData : { key: 'user_image' }"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <h1>Or</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Capture Image</h3>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-skeleton-loader v-if="loading"></v-skeleton-loader>
      <v-col v-if="!loading">
        <ImageCapturingComponent
          @imageCaptured="handleImageCaptureConversion"
          :fieldData="fieldData ? fieldData : { key: 'user_image' }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// :fieldData="{ key: 'user_image' }"
import ImageCapturingComponent from "./ImageCapturingComponent.vue";
import ImageUploadComponent from "./ImageUploadComponent.vue";
import InfoAlert from "@/components/InfoAlert.vue";
export default {
  name: "ImageComponent",
  props: ["conversionType", "fieldData", "compressImage"],
  components: {
    ImageCapturingComponent,
    ImageUploadComponent,
    InfoAlert,
  },
  data() {
    return {
      info: "",
      showDismissibleAlert: false,
      loading: false,
      textResult: null,
    };
  },
  methods: {
    async blobToBase64(blob) {
      if (blob) {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.readAsDataURL(blob);
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      }
      return null;
    },
    base64ToBlobConversion(image) {
      if (image && image.imageData) {
        let base64Data = image.imageData;
        const parts = base64Data.split(";base64,");
        if (parts.length !== 2) {
          throw new Error("Invalid Base64 format");
        }
        const mimeType = parts[0].split(":")[1];
        const byteCharacters = Buffer.from(parts[1], "base64");
        return new Blob([byteCharacters], { type: mimeType });
      }
      return null;
    },
    async handleImageUploadConversion(recievedImageData) {
      try {
        let processedImage = recievedImageData.imageData;
        if (this.compressImage) {
          processedImage = await this.resizeImage(processedImage, 100); // Target file size: 100KB
        }
        if (this.conversionType === "blob") {
          this.emitData(recievedImageData);
        } else {
          const base64String = await this.blobToBase64(processedImage);
          //   const base64String = await this.blobToBase64(recievedImageData);
          this.emitData(base64String);
        }
      } catch (error) {
        console.error("Error converting Blob to Base64:", error);
      }
    },
    async handleImageCaptureConversion(recievedImageData) {
      try {
        let processedImage = recievedImageData;

        if (this.compressImage) {
          processedImage = await this.resizeImage(
            await this.blobToBase64(recievedImageData),
            100
          );
        }
        if (this.conversionType === "blob") {
          const blob = this.base64ToBlobConversion(processedImage);
          //   const blob = this.base64ToBlobConversion(recievedImageData);
          this.emitData(blob);
        } else {
          this.emitData(processedImage.imageData);
          //   this.emitData(recievedImageData.imageData);
        }
      } catch (error) {
        console.error("Error converting Blob to Base64:", error);
      }
    },
    emitData(returnData) {
      let temp;
      if (this.fieldData) {
        temp = { ...this.fieldData };
        temp.imageData = returnData;
      } else {
        temp = returnData;
      }
      this.$emit("imageData", temp);
    },
    async resizeImage(dataUrl, targetFileSizeKb, maxDeviation = 50) {
      let originalFile = await this.urltoFile(
        dataUrl,
        "compressed.png",
        "image/png"
      );
      if (originalFile.size / 1000 < targetFileSizeKb) return dataUrl; // File is already smaller

      let low = 0.0;
      let middle = 0.5;
      let high = 1.0;
      let result = dataUrl;
      let file = originalFile;

      while (Math.abs(file.size / 1000 - targetFileSizeKb) > maxDeviation) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const img = document.createElement("img");

        const promise = new Promise((resolve, reject) => {
          img.onload = () => resolve();
          img.onerror = reject;
        });

        img.src = dataUrl;
        await promise;

        canvas.width = Math.round(img.width * middle);
        canvas.height = Math.round(img.height * middle);
        context.scale(canvas.width / img.width, canvas.height / img.height);
        context.drawImage(img, 0, 0);
        file = await this.urltoFile(
          canvas.toDataURL(),
          "compressed.png",
          "image/png"
        );

        if (file.size / 1000 < targetFileSizeKb - maxDeviation) {
          low = middle;
        } else if (file.size / 1000 > targetFileSizeKb) {
          high = middle;
        }

        middle = (low + high) / 2;
        result = canvas.toDataURL();
      }

      return result;
    },

    async urltoFile(url, filename, mimeType) {
      return fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mimeType }));
    },
  },
};
</script> -->

<template>
  <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-row>
      <v-skeleton-loader v-if="loading"></v-skeleton-loader>
      <v-col v-if="!loading">
        <ImageUploadComponent
          @imageUploaded="handleImageUploadConversion"
          :fieldData="fieldData ? fieldData : { key: 'user_image' }"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <h1>Or</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Capture Image</h3>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-skeleton-loader v-if="loading"></v-skeleton-loader>
      <v-col v-if="!loading">
        <ImageCapturingComponent
          @imageCaptured="handleImageCaptureConversion"
          :fieldData="fieldData ? fieldData : { key: 'user_image' }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImageCapturingComponent from "./ImageCapturingComponent.vue";
import ImageUploadComponent from "./ImageUploadComponent.vue";
import InfoAlert from "@/components/InfoAlert.vue";

export default {
  name: "OCRComponent",
  props: ["conversionType", "fieldData", "compressImage"],
  components: {
    ImageCapturingComponent,
    ImageUploadComponent,
    InfoAlert,
  },
  data() {
    return {
      info: "",
      showDismissibleAlert: false,
      loading: false,
      textResult: null,
    };
  },
  methods: {
    async blobToBase64(blob) {
      if (blob) {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.readAsDataURL(blob);
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      }
      return null;
    },

    base64ToBlobConversion(image) {
      if (image && image.imageData) {
        let base64Data = image.imageData;
        const parts = base64Data.split(";base64,");
        if (parts.length !== 2) {
          throw new Error("Invalid Base64 format");
        }
        const mimeType = parts[0].split(":")[1];
        const byteCharacters = Buffer.from(parts[1], "base64");
        return new Blob([byteCharacters], { type: mimeType });
      }
      return null;
    },

    async handleImageUploadConversion(recievedImageData) {
      try {
        let processedImage = recievedImageData;
        if (this.compressImage) {
          processedImage = await this.resizeImage(
            await this.blobToBase64(processedImage),
            50
          );
        }
        if (this.conversionType === "blob") {
          if (this.compressImage) {
            this.emitData(
              await this.blobToBase64({ imageData: processedImage })
            );
          } else {
            this.emitData(processedImage);
          }
        } else {
          if (this.compressImage) {
            this.emitData(processedImage);
          } else {
            this.emitData(
              await this.blobToBase64({ imageData: processedImage })
            );
          }
        }
      } catch (error) {
        console.error("Error processing uploaded image:", error);
      }
    },

    async handleImageCaptureConversion(recievedImageData) {
      try {
        let processedImage = recievedImageData.imageData;

        if (this.compressImage) {
          processedImage = await this.resizeImage(processedImage, 50);
        }
        if (this.conversionType === "blob") {
          const blob = this.base64ToBlobConversion(processedImage);
          this.emitData(blob);
        } else {
          this.emitData(processedImage);
        }
      } catch (error) {
        console.error("Error processing captured image:", error);
      }
    },

    emitData(returnData) {
      let temp;
      if (this.fieldData) {
        temp = { ...this.fieldData };
        temp.imageData = returnData;
      } else {
        temp = returnData;
      }
      this.$emit("imageData", temp);
    },

    async resizeImage(dataUrl, targetFileSizeKb = 90, maxDeviation = 5) {
      async function urltoFile(url, filename, mimeType) {
        const res = await fetch(url);
        const blob = await res.blob();
        return new File([blob], filename, { type: mimeType });
      }
      let originalFile = await urltoFile(dataUrl, "original.png", "image/png");
      console.log(
        `Original File Size: ${(originalFile.size / 1024).toFixed(2)} KB`
      );

      if (originalFile.size / 1024 <= targetFileSizeKb) return dataUrl; // No need to compress

      let low = 0.1;
      let middle = 0.5;
      let high = 1.0;
      let result = dataUrl;
      let file = originalFile;

      while (Math.abs(file.size / 1024 - targetFileSizeKb) > maxDeviation) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const img = document.createElement("img");

        const promise = new Promise((resolve, reject) => {
          img.onload = () => resolve();
          img.onerror = reject;
        });

        img.src = dataUrl;
        await promise;

        // Reduce dimensions for better compression
        canvas.width = Math.round(img.width * middle);
        canvas.height = Math.round(img.height * middle);
        context.drawImage(img, 0, 0, canvas.width, canvas.height);

        result = canvas.toDataURL("image/jpeg", middle); // Adjust quality
        file = await urltoFile(result, "compressed.jpg", "image/jpeg");

        if (file.size / 1024 < targetFileSizeKb - maxDeviation) {
          low = middle;
        } else if (file.size / 1024 > targetFileSizeKb) {
          high = middle;
        }

        middle = (low + high) / 2;
      }
      //   console.log(`Compressed File Size: ${(file.size / 1024).toFixed(2)} KB`);
      return result;
    },

    // async resizeImage(dataUrl, targetFileSizeKb, maxDeviation = 50) {
    //   let originalFile = await this.urltoFile(
    //     dataUrl,
    //     "compressed.png",
    //     "image/png"
    //   );
    //   if (originalFile.size / 1000 < targetFileSizeKb) return dataUrl; // File is already smaller

    //   let low = 0.0;
    //   let middle = 0.5;
    //   let high = 1.0;
    //   let result = dataUrl;
    //   let file = originalFile;

    //   while (Math.abs(file.size / 1000 - targetFileSizeKb) > maxDeviation) {
    //     const canvas = document.createElement("canvas");
    //     const context = canvas.getContext("2d");
    //     const img = document.createElement("img");

    //     const promise = new Promise((resolve, reject) => {
    //       img.onload = () => resolve();
    //       img.onerror = reject;
    //     });

    //     img.src = dataUrl;
    //     await promise;

    //     canvas.width = Math.round(img.width * middle);
    //     canvas.height = Math.round(img.height * middle);
    //     context.scale(canvas.width / img.width, canvas.height / img.height);
    //     context.drawImage(img, 0, 0);
    //     file = await this.urltoFile(
    //       canvas.toDataURL(),
    //       "compressed.png",
    //       "image/png"
    //     );

    //     if (file.size / 1000 < targetFileSizeKb - maxDeviation) {
    //       low = middle;
    //     } else if (file.size / 1000 > targetFileSizeKb) {
    //       high = middle;
    //     }

    //     middle = (low + high) / 2;
    //     result = canvas.toDataURL();
    //   }
    //   return result;
    // },

    // async urltoFile(url, filename, mimeType) {
    //   return fetch(url)
    //     .then((res) => res.arrayBuffer())
    //     .then((buf) => new File([buf], filename, { type: mimeType }));
    // },
  },
};
</script>
