<template>
  <v-container
    fluid
    class="no-padding bordered-container"
    :style="{
      'background-color': $store.getters.getColorPalette().backgroundColorCode,
    }"
  >
    <v-tabs
      :dark="$store.getters.getColorPalette().isDark"
      :background-color="$store.getters.getColorPalette().backgroundColorCode"
      v-model="tab"
      show-arrows
    >
      <v-tabs-slider
        :color="$store.getters.getColorPalette().accentCode"
      ></v-tabs-slider>
      <v-tab
        v-for="(item, index) in list.filter((item) => item.condition)"
        :key="index"
        :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().btnborderColorCode"
      >
        <v-icon
          small
          class="mr-2"
          :isDark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().accentCode"
        >
          {{ item.icon }}
        </v-icon>
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().backgroundColorCode"
    >
      <v-divider
        :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().backgroundColorCode"
        class="divider"
      ></v-divider>
      <v-tab-item
        v-for="(item, index) in list.filter((item) => item.condition)"
        :key="index"
      >
        <v-card
          flat
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().backgroundColorCode"
        >
          <v-card-text>
            <component :is="item.component"></component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import ManufacturingLogbookPage from "./logbook/ManufacturingLogbookPage.vue";
import ManufacturingLogbookConfigurationsPage from "./logbook/ManufacturingLogbookConfigurationsPage.vue";
import ManufacturingLogbookHistoricalPage from "./logbook/ManufacturingLogbookHistoricalPage.vue";
export default {
  name: "ManufacturingLogbook",
  components: {
    ManufacturingLogbookPage,
    ManufacturingLogbookConfigurationsPage,
    ManufacturingLogbookHistoricalPage,
  },
  data() {
    return {
      tab: 0, // Default tab index
      list: [
        {
          title: "Home",
          component: "ManufacturingLogbookPage",
          icon: "mdi-home-outline",
          condition: true,
        },
        {
          title: "History",
          component: "ManufacturingLogbookHistoricalPage",
          icon: "mdi-history",
          condition: true,
        },
        {
          title: "Configurations",
          component: "ManufacturingLogbookConfigurationsPage",
          icon: "mdi-cog-outline",
          condition:
            this.$store.state.user &&
            this.$store.getters.getAccessByLevels(["engineeraccess"]),
        },
      ],
    };
  },
  mounted() {
    if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
  },
};
</script>
<style scoped>
.divider {
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}
</style>
