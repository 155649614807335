<template>
  <v-container fluid class="no-padding">
    <ManufacturingOpenLogbookLists />
    <ManufacturingRefillLogbookList />
  </v-container>
</template>
<script>
import ManufacturingOpenLogbookLists from "@/components/lists/manufacturing/logbook/home/ManufacturingOpenLogbookLists.vue";
import ManufacturingRefillLogbookList from "@/components/lists/manufacturing/logbook/home/ManufacturingRefillLogbookList.vue";
export default {
  name: "ManufacturingLogbooksPanel",
  components: {
    ManufacturingOpenLogbookLists,
    ManufacturingRefillLogbookList,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
