<template>
  <div>
    <video ref="video" autoplay playsinline class="camera-feed"></video>
    <canvas ref="canvas" style="display: none;"></canvas>
    <v-row>
      <v-col>
        <v-btn
          small
          :color="$store.getters.getColorPalette().submitbtnColor"
          @click="captureImage"
        >
          Capture Image
        </v-btn>
      </v-col>
      <v-col>
        Make sure the camera is open & has all necessary permissions
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ImageCapturingComponent",
  props: ["fieldData"],
  data() {
    return {
      videoStream: null, // To store the media stream
    };
  },
  mounted() {
    this.startCamera();
  },
  methods: {
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" },
        });
        this.videoStream = stream;
        const video = this.$refs.video;
        video.srcObject = stream;
      } catch (error) {
        console.error("Error accessing the camera:", error);
        alert("Please grant camera access!");
      }
    },
    captureImage() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      // Set canvas size to match video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the current video frame to the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to a data URL (Base64 image)
      const imageData = canvas.toDataURL("image/png");

      // Send the image for OCR
      const returnData = { ...this.fieldData };
      returnData.imageData = imageData;
      this.$emit("imageCaptured", returnData);

      // console.log("Image captured:", imageData);
    },
    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
      }
    },
  },
  beforeDestroy() {
    this.stopCamera();
  },
};
</script>

<style>
.camera-feed {
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
