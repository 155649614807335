<template>
  <v-container fluid class="no-padding bordered-container">
    <div
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }"
    >
      <div>
        <v-btn
          :color="$store.getters.getColorPalette().btnColor"
          outlined
          text
          elevation="0"
          small
          @click="dialog = true"
        >
          {{ $store.getters.getTextMap().create }}
          {{ $store.getters.getTextMap().reference }}
          {{ $store.getters.getTextMap().sheet }}
        </v-btn>
        <v-btn
          class="ml-1"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().accentCode"
          small
          text
          outlined
          @click="handleRefreshData('reference_sheet')"
        >
          <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().downloadbtnColor"
            small
          >
            mdi-refresh
          </v-icon>
        </v-btn>
      </div>
    </div>
    <v-row no-gutters v-if="!loading">
      <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
      <v-col>
        <ManufacturingLogbookReferenceSheetList
          :refetchData="refetchData"
          v-on:editRefSheet="editSheet"
        />
      </v-col>
    </v-row>
    <div
      class="mb-1"
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }"
    >
      <div>
        <v-btn
          :color="$store.getters.getColorPalette().btnColor"
          outlined
          text
          elevation="0"
          small
          @click="templateDialog = true"
        >
          {{ $store.getters.getTextMap().create }}
          {{ $store.getters.getTextMap().log_book }}
          {{ $store.getters.getTextMap().template }}
        </v-btn>
        <v-btn
          class="ml-1"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().accentCode"
          small
          text
          outlined
          @click="handleRefreshData('template')"
        >
          <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().downloadbtnColor"
            small
          >
            mdi-refresh
          </v-icon>
        </v-btn>
      </div>
      <!-- <div :style="{ marginBottom: '5px' }">
        <v-text-field
          v-model="templateSearch"
          append-icon="mdi-magnify"
          :label="$store.getters.getTextMap().search"
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
      </div> -->
    </div>
    <v-row no-gutters class="mb-5" v-if="!loading">
      <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
      <v-col>
        <ManufacturingLogbookTemplateList
          :refetchData="refetchTemplateData"
          v-on:editTemplate="editTemplate"
        />
      </v-col>
    </v-row>
    <div
      class="mb-1"
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }"
    >
      <div>
        <v-btn
          :color="$store.getters.getColorPalette().btnColor"
          outlined
          text
          elevation="0"
          small
          @click="logbookDialog = true"
        >
          {{ $store.getters.getTextMap().create }}
          {{ $store.getters.getTextMap().log_book }}
        </v-btn>
        <v-btn
          class="ml-1"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().accentCode"
          small
          text
          outlined
          @click="handleRefreshData('logbook')"
        >
          <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().downloadbtnColor"
            small
          >
            mdi-refresh
          </v-icon>
        </v-btn>
      </div>
      <!-- <div :style="{ marginBottom: '5px' }">
        <v-text-field
          v-model="logbookSearch"
          append-icon="mdi-magnify"
          :label="$store.getters.getTextMap().search"
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
      </div> -->
    </div>
    <v-row no-gutters v-if="!loading">
      <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
      <v-col>
        <ManufacturingLogbookList
          :refetchData="refetchLogbookData"
          v-on:editLogbook="editLogbook"
        />
      </v-col>
    </v-row>
    <v-dialog
      :dark="$store.getters.getColorPalette().isDark"
      v-model="dialog"
      :color="$store.getters.getColorPalette().backgroundColorCode"
      hide-overlay
      transition="dialog-bottom-transition"
      :style="{
        border: '2px solid ' + $store.getters.getColorPalette().accentCode,
      }"
    >
      <CreateManufacturingLogbookreferenceSheet
        :editData="editData"
        v-on:closeForm="closeDialog"
        v-on:refetchData="handleRefreshData('reference_sheet')"
      />
    </v-dialog>
    <v-dialog
      :dark="$store.getters.getColorPalette().isDark"
      v-model="templateDialog"
      :color="$store.getters.getColorPalette().backgroundColorCode"
      hide-overlay
      transition="dialog-bottom-transition"
      :style="{
        border: '2px solid ' + $store.getters.getColorPalette().accentCode,
      }"
    >
      <CreateManufacturingLogbookTemplate
        :editTemplateData="editTemplateData"
        v-on:closeForm="closeTemplateDialog"
        v-on:refetchData="handleRefreshData('template')"
      />
    </v-dialog>
    <v-dialog
      :dark="$store.getters.getColorPalette().isDark"
      v-model="logbookDialog"
      :color="$store.getters.getColorPalette().backgroundColorCode"
      hide-overlay
      transition="dialog-bottom-transition"
      :style="{
        border: '2px solid ' + $store.getters.getColorPalette().accentCode,
      }"
    >
      <CreateManufacturingLogbook
        :editLogbookData="editLogbookData"
        v-on:closeForm="closeLogbookDialog"
        v-on:refetchData="handleRefreshData('logbook')"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import ManufacturingLogbookReferenceSheetList from "@/components/lists/manufacturing/logbook/configurations/ManufacturingLogbookReferenceSheetList.vue";
import ManufacturingLogbookTemplateList from "@/components/lists/manufacturing/logbook/configurations/ManufacturingLogbookTemplateList.vue";
import ManufacturingLogbookList from "@/components/lists/manufacturing/logbook/configurations/ManufacturingLogbookList.vue";
import CreateManufacturingLogbookreferenceSheet from "@/components/crud_components/manufacturing/logbook/configurations/CreateManufacturingLogbookreferenceSheet.vue";
import CreateManufacturingLogbookTemplate from "@/components/crud_components/manufacturing/logbook/configurations/CreateManufacturingLogbookTemplate.vue";
import CreateManufacturingLogbook from "@/components/crud_components/manufacturing/logbook/configurations/CreateManufacturingLogbook.vue";

export default {
  name: "ManufacturingLogbookReferenceSheetPanel",
  components: {
    ManufacturingLogbookReferenceSheetList,
    ManufacturingLogbookTemplateList,
    ManufacturingLogbookList,
    CreateManufacturingLogbookreferenceSheet,
    CreateManufacturingLogbookTemplate,
    CreateManufacturingLogbook,
  },
  data() {
    return {
      loading: false,
      refetchData: false,
      refetchTemplateData: false,
      refetchLogbookData: false,
      dialog: false,
      templateDialog: false,
      logbookDialog: false,
      editData: {},
      editTemplateData: {},
      editLogbookData: {},
    };
  },
  methods: {
    editSheet(value) {
      this.editData = value;
      if (
        this.editData &&
        this.editData.table_name &&
        this.editData.key_value
      ) {
        this.dialog = true;
      }
    },
    handleRefreshData(type) {
      this.loading = true;
      this.refetchData = false;
      this.refetchTemplateData = false;
      this.refetchLogbookData = false;
      switch (type) {
        case "reference_sheet":
          this.refetchData = true;
          break;
        case "template":
          this.refetchTemplateData = true;
          break;
        case "logbook":
          this.refetchLogbookData = true;
          break;
      }
      this.loading = false;
    },
    editTemplate(value) {
      this.editTemplateData = value;
      if (this.editTemplateData && this.editTemplateData.template_id) {
        this.templateDialog = true;
      }
    },
    editLogbook(value) {
      this.editLogbookData = value;
      this.logbookDialog = true;
    },
    closeDialog() {
      this.$emit("refreshData");
      this.editData = {};
      this.dialog = false;
    },
    closeTemplateDialog() {
      this.editTemplateData = {};
      this.templateDialog = false;
    },
    closeLogbookDialog() {
      this.editLogbookData = {};
      this.logbookDialog = false;
    },
  },
};
</script>
<style scoped>
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
