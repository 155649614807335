<template>
  <v-container
    fluid
    :style="{
      'background-color': $store.getters.getColorPalette().backgroundColorCode,
    }"
  >
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />

    <!-- config checklist button -->
    <div
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }"
    >
      <div :style="{ marginBottom: '5px' }">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$store.getters.getTextMap().search"
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
      </div>
      <div>
        <v-btn
          :color="$store.getters.getColorPalette().btnColor"
          outlined
          text
          elevation="0"
          small
          @click="dialog = !dialog"
        >
          {{ $store.getters.getTextMap().create_checklist }}
        </v-btn>
        <v-btn
          class="ml-1"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().accentCode"
          small
          text
          outlined
          @click="getChecklistTemplates"
        >
          <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().downloadbtnColor"
            small
          >
            mdi-refresh
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-dialog
      :dark="$store.getters.getColorPalette().isDark"
      v-model="dialog"
      :color="$store.getters.getColorPalette().backgroundColorCode"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card
        :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().backgroundColorCode"
        flat
      >
        <div class="d-flex justify-space-between align-center pa-3">
          <div>
            <v-card-text class="title">{{
              $store.getters.getTextMap().create_checklist
            }}</v-card-text>
          </div>

          <div>
            <v-btn
              :color="$store.getters.getColorPalette().submitbtnColor"
              @click="resetForm"
              class="mr-3"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>

        <v-form ref="form" v-model="valid">
          <v-row class="px-10">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                :label="$store.getters.getTextMap().name"
                v-model="name"
                dense
                outlined
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field :label="$store.getters.getTextMap().description" v-model="description" dense outlined>
                            </v-text-field>
                        </v-col> -->
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                :label="$store.getters.getTextMap().template"
                v-model="template"
                :items="templates"
                item-text="name"
                item-value="template_id"
                dense
                outlined
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                :rules="[rules.required]"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                :label="$store.getters.getTextMap().schedule"
                v-model="scheduleType"
                :items="scheduleTypes"
                item-text="label"
                item-value="value"
                dense
                outlined
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                :rules="[rules.required]"
              >
              </v-autocomplete>
              <v-text-field
                v-if="scheduleType == 'cron'"
                :label="$store.getters.getTextMap().schedule"
                v-model="schedule"
                dense
                outlined
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                :label="
                  $store.getters.getTextMap().closure +
                  ' ' +
                  $store.getters.getTextMap().duration +
                  '(' +
                  $store.getters.getTextMap().minutes +
                  ')'
                "
                v-model="closure_duration"
                dense
                outlined
                :rules="[rules.integ]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                :label="$store.getters.getTextMap().maker"
                v-model="maker"
                :items="userGroups"
                item-text="name"
                item-value="group_id"
                dense
                outlined
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                :rules="[rules.required]"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                :label="$store.getters.getTextMap().checker"
                v-model="checker"
                :items="userGroups"
                item-text="name"
                item-value="group_id"
                dense
                outlined
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                :label="$store.getters.getTextMap().approver"
                v-model="approver"
                :items="userGroups"
                item-text="name"
                item-value="group_id"
                dense
                outlined
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-autocomplete
                :label="$store.getters.getTextMap().issuer"
                v-model="issuer"
                :items="userGroups"
                item-text="name"
                item-value="group_id"
                dense
                outlined
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-col cols="12" class="d-flex justify-center pa-8">
            <v-btn
              :color="$store.getters.getColorPalette().submitbtnColor"
              @click="onSubmit"
              class="mr-3"
            >
              {{ $store.getters.getTextMap().submit }}
            </v-btn>
            <v-btn
              :color="$store.getters.getColorPalette().cancelbtnColor"
              :dark="$store.getters.getColorPalette().isDark"
              @click="onReset"
              class="ml-3"
            >
              {{ $store.getters.getTextMap().cancel }}
            </v-btn>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- List of added checklist Templates -->
    <v-row>
      <v-col cols="12">
        <!-- data table -->
        <v-data-table
          :headers="headers"
          :items="checklists"
          item-key="checklist_id"
          :search="search"
          :single-expand="true"
          show-expand
          :expanded.sync="expanded"
          :loading="loading"
          loading-text="Loading... Please wait"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :style="{
            border:
              '1px solid' +
              $store.getters.getColorPalette().panelBorderColorCode,
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{
                $store.getters.getTextMap().checklists
              }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td v-if="cancelChecklist" :colspan="headers.length">
              <v-row class="mt-1">
                <v-col>
                  <h2
                    :style="{
                      color: $store.getters.getColorPalette().accentCode,
                    }"
                  >
                    {{ $store.getters.getTextMap().cancel }}
                    {{ $store.getters.getTextMap().check_list }}
                  </h2>
                </v-col>
              </v-row>
              <div
                v-if="
                  cancelChecklistDetails && cancelChecklistDetails.checklist_id
                "
              >
                <v-card
                  class="my-5"
                  flat
                  outlined
                  :dark="$store.getters.getColorPalette().isDark"
                  :color="$store.getters.getColorPalette().background2ColorCode"
                  style="border-radius: 12px"
                >
                  <div
                    :style="{
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }"
                  >
                    <div>
                      {{ cancelLabelTypeMap[cancelChecklistDetails.req_type] }}
                      {{ $store.getters.getTextMap().is_be_cancelled }}
                    </div>
                    <div>
                      <DeleteConfirmation
                        v-on:confirm="deleteCancelledChecklistEvent(item)"
                        title="Delete Confirmation"
                        description="Are you sure you want to delete this Event?"
                      >
                        <v-btn
                          class="mx-1"
                          :color="$store.getters.getColorPalette().accentCode"
                          small
                          text
                          outlined
                        >
                          {{ $store.getters.getTextMap().delete_this }}
                          {{ $store.getters.getTextMap().event }}

                          <v-icon
                            right
                            :isDark="$store.getters.getColorPalette().isDark"
                            :color="
                              $store.getters.getColorPalette().deletebtnColor
                            "
                            small
                            >mdi-trash-can</v-icon
                          >
                        </v-btn>
                      </DeleteConfirmation>
                    </div>
                  </div>
                </v-card>
                <v-row no-gutters class="mb-5">
                  <v-col align="right">
                    <v-skeleton-loader
                      v-if="loading"
                      type="text"
                    ></v-skeleton-loader>
                    <v-btn
                      class="mx-1"
                      v-if="!loading"
                      :color="$store.getters.getColorPalette().cancelbtnColor"
                      small
                      @click="cancelChecklist = false"
                      >{{ $store.getters.getTextMap().cancel }}</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row class="mt-5">
                  <v-col>
                    <v-form ref="checklistCancelForm" v-model="isFormValid">
                      <v-select
                        v-if="!loading"
                        v-model="req_type"
                        :items="cancelTypeOption"
                        :label="$store.getters.getTextMap().type"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{
                          dark: $store.getters.getColorPalette().isDark,
                        }"
                        :rules="[rules.required]"
                        :dark="$store.getters.getColorPalette().isDark"
                      ></v-select>
                    </v-form>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mb-5">
                  <v-col align="right">
                    <v-skeleton-loader
                      v-if="loading"
                      type="text"
                    ></v-skeleton-loader>
                    <v-btn
                      class="mx-1"
                      v-if="!loading"
                      :color="$store.getters.getColorPalette().cancelbtnColor"
                      small
                      @click="cancelChecklist = false"
                      >{{ $store.getters.getTextMap().cancel }}</v-btn
                    >
                    <v-btn
                      v-if="!loading"
                      :color="$store.getters.getColorPalette().submitbtnColor"
                      small
                      @click="checklistCancelSubmit(item)"
                      >{{ $store.getters.getTextMap().submit }}</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </td>
            <td v-else :colspan="headers.length">
              <v-row no-gutters>
                <v-col
                  v-if="
                    $store.state.user &&
                    $store.state.settingMode &&
                    $store.getters.getAccessByLevels(['alphaaccess'])
                  "
                >
                  <v-chip
                    small
                    :dark="$store.getters.getColorPalette().isDark"
                    :color="
                      $store.getters.getColorPalette().background2ColorCode
                    "
                    ><v-avatar
                      :dark="$store.getters.getColorPalette().isDark"
                      :style="{
                        color:
                          $store.getters.getColorPalette().background2ColorCode,
                      }"
                      :class="
                        $store.getters.getColorPalette().foregroundColorName +
                        ' ma-1 px-1'
                      "
                      ><v-icon
                        :color="$store.getters.getColorPalette().accentCode"
                        small
                        >mdi-identifier</v-icon
                      ></v-avatar
                    >{{ item.checklist_id }}</v-chip
                  >
                </v-col>
                <v-col>
                  <v-btn
                    class="mx-1"
                    :color="$store.getters.getColorPalette().accentCode"
                    small
                    text
                    outlined
                    v-on:click="editChecklist(item)"
                    >{{ $store.getters.getTextMap().edit_checklist }}

                    <v-icon
                      right
                      :isDark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().deletebtnColor"
                      small
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    class="mx-1"
                    :color="$store.getters.getColorPalette().accentCode"
                    small
                    text
                    outlined
                    v-on:click="getCancelChecklistDetails(item)"
                    >{{ $store.getters.getTextMap().cancel }}
                    {{ $store.getters.getTextMap().check_list }}

                    <v-icon
                      right
                      :isDark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().deletebtnColor"
                      small
                      >mdi-cancel</v-icon
                    >
                  </v-btn>
                </v-col>
                <v-col>
                  <DeleteConfirmation
                    v-on:confirm="deleteChecklist(item)"
                    title="Delete Confirmation"
                    description="Are you sure you want to delete this Log?"
                  >
                    <v-btn
                      class="mx-1"
                      :color="$store.getters.getColorPalette().accentCode"
                      small
                      text
                      outlined
                    >
                      {{ $store.getters.getTextMap().delete_checklist }}

                      <v-icon
                        right
                        :isDark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().deletebtnColor"
                        small
                        >mdi-trash-can</v-icon
                      >
                    </v-btn>
                  </DeleteConfirmation>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InfoAlert from "@/components/InfoAlert.vue";
import axios from "axios";
import FormRules from "@/utillities/FormRules";

import DeleteConfirmation from "@/components/DeleteConfirmation";
export default {
  name: "ConfigCheckLists",
  components: {
    InfoAlert,
    DeleteConfirmation,
  },
  data() {
    return {
      info: "",
      showDismissibleAlert: false,
      loading: false,
      isFormValid: false,
      templates: [],
      expanded: [],
      dialog: false,
      valid: false,
      name: null,
      req_type: "next",
      cancelChecklist: false,
      // description:null,
      template: null,
      schedule: null,
      scheduleType: "cron",
      scheduleTypes: [
        { label: "Cron", value: "cron" },
        { label: "Auto trigger on submit", value: "adhoc" },
        { label: "Manual", value: "manual" },
      ],
      closure_duration: null,
      maker: null,
      checker: null,
      approver: null,
      issuer: null,
      edit: false,
      endpoint: "createChecklist",
      rules: FormRules.rules,
      checklists: [],
      search: "",
      sortDesc: false,
      selectedChecklist: null,
      sortBy: "name",
      singleExpand: false,
      cancelChecklistDetails: {},
      cancelTypeOption: [
        { label: "Next Checklist In Queue", value: "next" },
        { label: "All Checklists in the Series", value: "series" },
      ],
      cancelLabelTypeMap: {
        next: "Next Checklist In Queue",
        series: "All Checklists in the Series",
      },
      headers: [
        {
          text: "Name",
          align: "start",
          filterable: true,
          value: "name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Maker",
          value: "maker_group_name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Checker",
          value: "checker_group_name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Approver",
          value: "approver_group_name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Issuer",
          value: "issuer_group_name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "ID",
          value: "checklist_id",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
      ],
    };
  },
  mounted() {
    this.getChecklistTemplates();
    this.getChecklists();
  },

  computed: {
    userGroups() {
      let op = [];

      op = this.$store.getters.getUserGroups();

      return op;
    },
  },

  methods: {
    editChecklist(item) {
      this.edit = true;
      this.selectedChecklist = item;
      this.name = item.name;
      this.template = item.template_id;
      this.schedule = item.schedule;
      if (
        item.schedule &&
        (item.schedule == "adhoc" || item.schedule == "manual")
      ) {
        this.scheduleType = item.schedule;
      }
      this.closure_duration = item.closure_duration;
      this.maker = item.maker;
      this.checker = item.checker;
      this.approver = item.approver;
      this.issuer = item.issuer;
      this.dialog = true;
    },

    getChecklists() {
      this.loading = true;
      axios
        .get(this.$store.state.api + "checklists", {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          if (response.data.data.length > 0) {
            response.data.data.map((item) => {
              // item['maker_group_name'] = this.userGroups.map(k => {
              //     if (item.maker === k.group_id) {
              //         return k.name
              //     }
              // })[0]
              item["maker_group_name"] = this.userGroups.find((k) => {
                return item.maker === k.group_id;
              })?.name;
              // item['approver_group_name'] = this.userGroups.map(k => {
              //     if (item.approver === k.group_id) {
              //         return k.name
              //     }
              // })[0]
              item["approver_group_name"] = this.userGroups.find((k) => {
                return item.approver === k.group_id;
              })?.name;
              // item['checker_group_name'] = this.userGroups.map(k => {
              //     if (item.checker === k.group_id) {
              //         return k.name
              //     }
              // })[0]
              item["checker_group_name"] = this.userGroups.find((k) => {
                return item.checker === k.group_id;
              })?.name;
              // item['issuer_group_name'] = this.userGroups.map(k => {
              //     if (item.issuer === k.group_id) {
              //         return k.name
              //     }
              // })[0]
              item["issuer_group_name"] = this.userGroups.find((k) => {
                return item.issuer === k.group_id;
              })?.name;
            });
          }
          this.checklists = [...response.data.data];
          if (response.data.status === "success") {
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          // console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    resetForm() {
      this.name = null;
      this.template = null;
      this.schedule = null;
      this.closure_duration = null;
      this.maker = null;
      this.checker = null;
      this.approver = null;
      this.issuer = null;
      this.edit = false;
      this.selectedChecklist = null;
      this.dialog = false;
    },
    getChecklistTemplates() {
      this.loading = true;
      axios
        .get(this.$store.state.api + "checklistTemplates", {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          this.templates = [...response.data.data];
          if (response.data.status === "success") {
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);

          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    checklistCancelSubmit(checklist) {
      if (this.$refs.checklistCancelForm.validate()) {
        this.loading = true;
        let payload = {
          req_type: this.req_type,
          checklist_id: checklist.checklist_id,
        };
        axios
          .post(this.$store.state.api + "cancelChecklist", payload, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              this.getCancelChecklistDetails(checklist);
              this.info = "Checklist  Cancelled";
              this.showDismissibleAlert = true;
              this.loading = false;
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
              this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.info = err;
            this.loading = false;
          });
      }
    },
    deleteChecklist(d) {
      axios
        .post(
          this.$store.state.api + "deleteChecklist",
          { checklist_id: d.checklist_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.info = "Checklist  Deleted";
            this.showDismissibleAlert = true;
            this.loading = false;
            this.getChecklists();
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    deleteCancelledChecklistEvent(d) {
      axios
        .post(
          this.$store.state.api + "deleteCancelledChecklistEvent",
          { checklist_id: d.checklist_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.getCancelChecklistDetails(d);
            this.info = "Event  Deleted";
            this.showDismissibleAlert = true;
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },

    onSubmit() {
      if (this.scheduleType && this.scheduleType != "cron") {
        this.schedule = this.scheduleType;
      }
      if (!this.edit) {
        this.$refs.form.validate();
        if (this.valid) {
          let payload = {
            name: this.name,
            template_id: this.template,
            schedule: this.schedule,
            closure_duration: this.closure_duration,
            maker: this.maker,
            checker: this.checker,
            approver: this.approver,
            issuer: this.issuer,
          };
          this.loading = true;
          axios
            .post(this.$store.state.api + this.endpoint, payload, {
              headers: {
                Authorization: "Bearer " + this.$store.state.jwt,
              },
            })
            .then((response) => {
              if (response.data.status === "success") {
                this.info = "Checklist Created";
                this.getChecklists();
                this.showDismissibleAlert = true;
                this.resetForm();
                this.loading = false;
              } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
                this.loading = false;
              }
              this.loading = false;
            })
            .catch((err) => {
              this.showDismissibleAlert = true;
              this.info = err;
              this.loading = false;
            });
        }
      }
      if (this.edit && this.selectedChecklist) {
        let payload = {
          name: this.name,
          template_id: this.template,
          schedule: this.schedule,
          closure_duration: this.closure_duration,
          maker: this.maker,
          checker: this.checker,
          approver: this.approver,
          issuer: this.issuer,
          checklist_id: this.selectedChecklist.checklist_id,
        };
        this.loading = true;
        axios
          .post(this.$store.state.api + "updateChecklist", payload, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              // console.log(response);
              this.info = "Checklist Updated";
              this.getChecklists();
              this.showDismissibleAlert = true;
              this.loading = false;
              this.resetForm();
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
              this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.info = err;
            this.loading = false;
          });
      }
    },
    getCancelChecklistDetails(checklist) {
      this.cancelChecklist = true;
      axios
        .post(
          this.$store.state.api + "getCancelledChecklistById",
          { checklist_id: checklist.checklist_id },
          { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.cancelChecklistDetails = response.data.data;
            this.loading = false;
          } else {
            // this.info = response.data.msg;
            // this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    onReset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style></style>
