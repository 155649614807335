<template>
  <v-container fluid class="no-padding">
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
    />
    <v-card
      flat
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().background2ColorCode"
    >
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col
            ><v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-text-field
              v-if="!loading"
              :label="
                $store.getters.getTextMap().table +
                ' ' +
                $store.getters.getTextMap().name
              "
              :placeholder="
                $store.getters.getTextMap().table +
                ' ' +
                $store.getters.getTextMap().name
              "
              v-model="table_key"
              :rules="[rules.required]"
              outlined
              dense
            >
            </v-text-field
          ></v-col>
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-select
              placeholder="Table Type"
              v-model="table_type"
              :rules="[rules.required]"
              :items="tableTypeOption"
              item-text="label"
              item-value="value"
              outlined
              dense
            ></v-select></v-col
        ></v-row>
      </v-form>
      <v-simple-table
        fixed-header
        dense
        :style="{
          border: '2px solid ' + $store.getters.getColorPalette().accentCode,
          'background-color':
            $store.getters.getColorPalette().backgroundColorCode,
        }"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-for="(item, index) in tableData.headers"
                class="text-left"
                :key="item"
                :class="$store.getters.getColorPalette().foregroundColorName"
                :color="$store.getters.getColorPalette().accentCode"
              >
                <strong>{{ item }}</strong>
                <span>
                  <v-btn small icon @click="editHeader(index)" color="blue">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn small icon color="red">
                    <DeleteConfirmation
                      v-on:confirm="deleteHeader(index)"
                      title="Delete Confirmation"
                      description="Are you sure you want to delete this?"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </DeleteConfirmation>
                  </v-btn>
                </span>
              </th>
              <th
                :class="
                  'text-left  text-subtitle-1 text-capitalize ' +
                  $store.getters.getColorPalette().foregroundColorName
                "
              >
                <v-btn
                  small
                  icon
                  @click="editHeader(tableData.headers.length)"
                  :color="$store.getters.getColorPalette().accentCode"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </th>
              <th
                :class="
                  'text-right text-subtitle-1 text-capitalize ' +
                  $store.getters.getColorPalette().foregroundColorName
                "
              >
                Actions
              </th>
            </tr>
          </thead>
          <v-divider></v-divider>
          <tbody
            :style="{
              'background-color':
                $store.getters.getColorPalette().backgroundColorCode,
            }"
            v-if="!loading && tableData"
          >
            <tr
              v-for="(item, itemIndex) in tableData.rows"
              :key="itemIndex"
              :style="{
                'background-color':
                  $store.getters.getColorPalette().background1ColorCode,
              }"
            >
              <td class="text-left" v-for="(x, index) in item" :key="index">
                {{ x.type === "static" ? x.value : x.value.type }}
                <v-btn
                  small
                  icon
                  @click="editRowItem(itemIndex, index)"
                  color="blue"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  small
                  icon
                  :color="$store.getters.getColorPalette().deletebtnColor"
                >
                  <DeleteConfirmation
                    v-on:confirm="deleteRowItem(itemIndex, index)"
                    title="Delete Confirmation"
                    description="Are you sure you want to delete this?"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </DeleteConfirmation>
                </v-btn>
              </td>
              <td class="text-left text-capitalize">
                <v-btn
                  small
                  @click="editRowItem(itemIndex, item.length)"
                  icon
                  color="green"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </td>
              <td class="text-right text-capitalize">
                <v-btn
                  icon
                  :color="$store.getters.getColorPalette().deletebtnColor"
                >
                  <DeleteConfirmation
                    v-on:confirm="deleteRow(itemIndex)"
                    title="Delete Confirmation"
                    description="Are you sure you want to delete this?"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </DeleteConfirmation>
                </v-btn>
              </td>
            </tr>
            <tr
              v-if="!(table_type === 'dynamic' && tableData.rows.length !== 0)"
            >
              <td>
                <v-btn
                  :dark="$store.getters.getColorPalette().isDark"
                  @click="addRow"
                  small
                  text
                  outlined
                  >Add Row
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <GetTextModal
      :show="headerEdit"
      v-on:close="headerEdit = false"
      v-on:value="updateHeader"
    />
    <GetManufacturingLogbookFieldModal
      :show="rowEdit"
      v-on:close="rowEdit = false"
      v-on:value="updateRowItem"
    />
    <v-row class="mt-1">
      <v-col align="right">
        <v-skeleton-loader
          v-if="loading"
          class="mx-auto"
          type="text"
        ></v-skeleton-loader>
        <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().accentCode"
          outlined
          text
          @click="submit()"
          >{{
            editable
              ? $store.getters.getTextMap().update
              : $store.getters.getTextMap().add
          }}
          {{ $store.getters.getTextMap().table }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FormRules from "@/utillities/FormRules";
import InfoAlert from "@/components/InfoAlert.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import GetTextModal from "@/components/modals/GetTextModal";
import GetManufacturingLogbookFieldModal from "@/components/modals/manufacturing/logbook/GetManufacturingLogbookFieldModal.vue";
export default {
  name: "ManufacturingLogbookTableForm",
  props: ["table_details", "editable"],
  components: {
    InfoAlert,
    DeleteConfirmation,
    GetTextModal,
    GetManufacturingLogbookFieldModal,
  },
  data() {
    return {
      rules: FormRules.rules,
      info: "",
      showDismissibleAlert: false,
      loading: false,
      headerEdit: false,
      headerIndex: null,
      rowIndex: null,
      rowEdit: false,
      columnData: null,
      valid: false,
      table_key: null,
      table_type: "static",
      tableTypeOption: [
        { label: "Dynamic Table", value: "dynamic" },
        { label: "Static Table", value: "static" },
      ],
      tableData: {
        headers: [],
        rows: [],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // Headers
    init() {
      if (
        this.table_details &&
        this.table_details.data_meta &&
        Object.keys(this.table_details.data_meta) &&
        Object.keys(this.table_details.data_meta).length > 0
      ) {
        this.tableData = this.table_details.data_meta;
        this.table_key = this.table_details.table_key;
        this.table_type = this.table_details.table_type;
      } else {
        this.table_key = null;
        this.table_type = "static";
        this.tableData = {
          headers: [],
          rows: [],
        };
      }
    },
    editHeader(index) {
      this.loading = true;
      this.headerIndex = index;
      this.headerEdit = true;
      this.loading = false;
    },
    updateHeader(value) {
      this.loading = true;
      let headers = this.tableData["headers"].slice();
      headers[this.headerIndex] = value;
      this.tableData["headers"] = headers;
      this.loading = false;
    },
    deleteHeader(index) {
      this.loading = true;
      this.tableData.headers.splice(index, 1);
      this.loading = false;
    },

    // Rows
    addRow() {
      this.tableData.rows.push([]);
    },
    deleteRow(index) {
      this.loading = true;
      this.tableData.rows.splice(index, 1);
      this.loading = false;
    },

    // Row Item
    editRowItem(itemIndex, index) {
      this.loading = true;
      this.rowIndex = [itemIndex, index];
      this.rowEdit = true;
      this.loading = false;
    },
    updateRowItem(value) {
      this.loading = true;
      let rows = this.tableData["rows"][this.rowIndex[0]].slice();
      rows[this.rowIndex[1]] = value;
      this.tableData["rows"][this.rowIndex[0]] = rows;
      this.loading = false;
    },
    deleteRowItem(itemIndex, index) {
      this.loading = true;
      this.tableData["rows"][itemIndex].splice(index, 1);
      this.loading = false;
    },
    submit() {
      this.$refs.form.validate();
      if (this.valid) {
        let payload = {
          data_meta: this.tableData,
          table_key: this.table_key,
          table_type: this.table_type,
        };
        this.$emit(this.editable ? "update" : "value", payload);
      }
    },
  },
  watch: {
    table_data: {
      handler() {
        this.init();
      },
      deep: true,
    },
    // table_type: {
    //   handler() {
    //     if (this.table_type === "dynamic") {
    //       // this.tableData.rows = [];
    //       this.addRow();
    //     }
    //   },
    //   deep: true,
    // },
  },
};
</script>
<style scoped>
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
