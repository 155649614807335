<template>
  <v-container fluid>
    <v-row no-gutters class="ma-0 pa-0">
      <v-col>
        <ManufacturingLogbooksPanel />
      </v-col> </v-row
  ></v-container>
</template>
<script>
import ManufacturingLogbooksPanel from "@/components/panels/manufacturing/logbooks/home/ManufacturingLogbooksPanel";
export default {
  name: "ManufacturingLogbookPage",
  components: {
    ManufacturingLogbooksPanel,
  },
};
</script>
