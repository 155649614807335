<template>
  <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <span v-if="!dependenciesLoaded">
      <LoadingLinear text="Logbooks are loading..." />
    </span>
    <span v-else>
      <div :style="{ display: 'flex', alignItems: 'center' }">
        <h2 :style="{ color: $store.getters.getColorPalette().accentCode }">
          Ongoing Logbooks
        </h2>
        <v-btn
          class="ml-2"
          :background-color="
            $store.getters.getColorPalette().background1ColorCode
          "
          :color="$store.getters.getColorPalette().accentCode"
          :dark="$store.getters.getColorPalette().isDark"
          outlined
          small
          text
          @click="init"
        >
          <v-icon
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().btnborderColorCode"
            >mdi-refresh</v-icon
          >
        </v-btn>
      </div>
      <v-row no-gutters class="mt-2">
        <v-skeleton-loader
          v-if="loading"
          class="mx-auto mt-5"
          type="text"
        ></v-skeleton-loader>
        <v-col v-if="logbookTable && logbookTable.length > 0">
          <v-divider :dark="$store.getters.getColorPalette().isDark" />
          <v-data-table
            :headers="headers"
            :items="logbookTable"
            item-key="index"
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :dark="$store.getters.getColorPalette().isDark"
            outlined
            style="background: transparent"
          >
            <template v-slot:item.status="{ item }">
              <div :style="getStatusStyle(item.status)">
                {{ statusNameMap[item.status] }}
              </div>
            </template>

            <!-- Action Button Column -->
            <template v-slot:item.actions="{ item }">
              <div v-if="item.stage === 'maker'">
                <v-btn
                  v-if="item.fill_started"
                  small
                  outlined
                  text
                  :dark="$store.getters.getColorPalette().isDark"
                  @click="startLogbookFilling(item)"
                >
                  Resume Writing
                </v-btn>
                <v-btn
                  v-else-if="isUserFromMakerGroup()"
                  small
                  outlined
                  text
                  :dark="$store.getters.getColorPalette().isDark"
                  @click="startLogbookFilling(item)"
                >
                  Start Writing
                </v-btn>
                <div v-else>No one has started writing</div>
              </div>
              <div v-else>
                <v-btn
                  small
                  outlined
                  text
                  :dark="$store.getters.getColorPalette().isDark"
                  @click="startLogbookFilling(item)"
                >
                  View Details
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <v-divider :dark="$store.getters.getColorPalette().isDark" />
          <!-- <v-data-table
            :headers="headers"
            :items="logbookTable"
            item-key="index"
            :search="search"
            :single-expand="true"
            show-expand
            :expanded.sync="expanded"
            :loading="loading"
            loading-text="Loading... Please wait"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :style="{
              border:
                '1px solid' +
                $store.getters.getColorPalette().panelBorderColorCode,
            }"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{
                  $store.getters.getTextMap().checklists
                }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
            </template>
            <template v-slot:item.status="{ item }">
              <div :style="getStatusStyle(item.status)">
                {{ statusNameMap[item.status] }}
              </div>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" v-if="item.stage === 'maker'">
                <ChecklistMakerForm :checklist="item" v-on:submitted="init" />
              </td>
              <td
                :colspan="headers.length"
                v-else-if="
                  item.stage === 'checker' || item.stage === 'approver'
                "
              >
                <ChecklistFormDetails
                  :checklist="item"
                  v-on:submitted="init"
                  v-on:refillChecklist="init"
                  :readOnly="false"
                />
              </td>
            </template>
          </v-data-table> -->
        </v-col>
        <v-col v-else-if="!loading">
          <div
            :style="[
              {
                padding: '10px',
                display: 'flex',
                'justify-content': 'center',
                color: $store.getters.getColorPalette().accentCode,
              },
            ]"
          >
            <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3"
              >mdi-database-remove-outline</v-icon
            >
            <h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
          </div>
        </v-col>
      </v-row>
    </span>
    <v-row>
      <v-col>
        <v-dialog
          :dark="$store.getters.getColorPalette().isDark"
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          :style="{
            border: '2px solid ' + $store.getters.getColorPalette().accentCode,
          }"
        >
          <ManufacturingLogbookMakerForm
            :logbookData="logbookData"
            :readOnly="false"
            v-on:closeForm="closeDialog"
            v-on:submitted="getData"
          />
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import InfoAlert from "@/components/InfoAlert";
// import DeleteConfirmation from "@/components/DeleteConfirmation";
import axios from "axios";
import moment from "moment";
import LoadingLinear from "@/components/display_components/LoadingLinear.vue";
import ManufacturingLogbookMakerForm from "@/components/forms/manufacturing/logbook/ManufacturingLogbookMakerForm.vue";
export default {
  name: "ManufacturingOpenLogbookLists",
  components: {
    InfoAlert,
    LoadingLinear,
    ManufacturingLogbookMakerForm,
    // DeleteConfirmation,
  },
  data() {
    return {
      showDismissibleAlert: false,
      info: "",
      loading: false,
      singleExpand: false,
      search: "",
      sortDesc: false,
      sortBy: "timestamp",
      expanded: [],
      openLogbooks: [],
      userGroups: [],
      logbookTable: [],
      dialog: false,
      logbookData: {},
      statusNameMap: {
        in_process: "In Process",
        rejected: "Rejected",
        completed: "Completed",
        refill: "Refill",
      },
      headers: [],
    };
  },
  mounted() {
    this.getUserGroups();
    this.init();
  },
  computed: {
    dependenciesLoaded() {
      let loaded = false;
      loaded = this.$store.state.manufacturingLogbooksMakersListLoaded;
      loaded = this.$store.state.manufacturingLogbooksPowerMakersListLoaded;
      loaded = this.$store.state.manufacturingLogbooksApproversListLoaded;
      loaded = this.$store.state.manufacturingLogbooksCheckersListLoaded;
      return loaded;
    },
  },
  methods: {
    init() {
      this.getData();
    },
    closeDialog() {
      this.logbookData = {};
      this.dialog = false;
      this.init();
    },
    getTimeDifference(tz, closure_duration, checklist_type) {
      const givenTime = moment.utc(tz);
      const now = moment.utc();
      const minutesPassed = now.diff(givenTime, "minutes");
      const remainingMinutes = closure_duration - minutesPassed;

      if (remainingMinutes <= 0) {
        return `${checklist_type === "adhoc" ? "-" : "Closed"}`;
      } else if (remainingMinutes > 1440) {
        const remainingDays = Math.round(remainingMinutes / 1440);
        return `${remainingDays} days`;
      } else if (remainingMinutes < 60) {
        return `${remainingMinutes} mins`;
      } else {
        return `${Math.round(remainingMinutes / 60)} hrs`;
      }
    },
    startLogbookFilling(logbook) {
      this.logbookData = logbook;
      if (logbook.fill_started && logbook.fill_started_by) {
        this.dialog = true;
      } else {
        let payload = {
          logbook_id: logbook.logbook_id,
          timestamp: Number(logbook.epoch_timestamp),
        };
        axios
          .post(
            this.$store.state.api + "startManufacturingLogbookFill",
            payload,
            {
              headers: { Authorization: "Bearer " + this.$store.state.jwt },
            }
          )
          .then((response) => {
            if (response.data.status === "success") {
              // this.$emit("submitted");
              // this.info = "You can start filling the checklist";
              // this.showDismissibleAlert = true;
              this.getData();
              this.loading = false;
              this.dialog = true;
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
              this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.showDismissibleAlert = true;
            this.info = err;
            this.loading = false;
          });
      }
    },
    isLogbookFillStarted(logbook) {
      if (logbook.stage === "maker" || logbook.stage === "power_maker") {
        return (
          !logbook.fill_started ||
          logbook.fill_started_by === this.$store.state.user.userid ||
          this.isUserPresentInLognookGroup(
            logbook.power_maker,
            this.$store.state.user.userid
          )
        );
      }
      return true;
    },
    getUserGroups() {
      this.loading = true;
      axios
        .get(this.$store.state.api + "userGroupsDetailsList", {
          headers: { Authorization: "Bearer " + this.$store.state.jwt },
        })
        .then((response) => {
          this.userGroups = [...response.data.groups];
          if (response.data.status === "success") {
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    isUserPresentInLognookGroup(group_id, user_id) {
      let userGroupDetails = this.userGroups.filter(
        (x) => x.group_id === group_id
      );
      let userIds = [];
      if (userGroupDetails) {
        for (let i of userGroupDetails) {
          userIds.push(i.userid);
        }
        return userIds.includes(user_id);
      }
      return false;
    },
    isUserFromMakerGroup() {
      let makersUserids = this.$store.state.manufacturingLogbooksMakersList.map(
        (x) => x.userid
      );
      return makersUserids.includes(this.$store.state.user.userid);
    },
    createLogbooksByStage() {
      let user_id =
        this.$store.state.user && this.$store.state.user.userid
          ? this.$store.state.user.userid
          : null;
      let makersUserids = this.$store.state.manufacturingLogbooksMakersList.map(
        (x) => x.userid
      );
      let powerMakersUserids =
        this.$store.state.manufacturingLogbooksPowerMakersList.map(
          (x) => x.userid
        );
      let approversUserids =
        this.$store.state.manufacturingLogbooksApproverLists.map(
          (x) => x.userid
        );
      let checkersUserids =
        this.$store.state.manufacturingLogbooksCheckersList.map(
          (x) => x.userid
        );
      this.logbookTable = [];
      let temp = [];
      if (
        (makersUserids &&
          makersUserids.length > 0 &&
          makersUserids.includes(user_id)) ||
        (powerMakersUserids &&
          powerMakersUserids.length > 0 &&
          powerMakersUserids.includes(user_id))
      ) {
        temp = temp.concat(
          this.openLogbooks.filter(
            (x) =>
              (x.stage === "maker" || x.stage === "power_maker") &&
              (this.isUserPresentInLognookGroup(x.maker, user_id) ||
                this.isUserPresentInLognookGroup(x.power_maker, user_id))
          )
        );
      }
      // if (
      //   powerMakersUserids &&
      //   powerMakersUserids.length > 0 &&
      //   powerMakersUserids.includes(user_id)
      // ) {
      //   temp = temp.concat(
      //     this.openLogbooks.filter(
      //       (x) =>
      //         x.stage === "power_maker" &&
      //         this.isUserPresentInLognookGroup(x.power_maker, user_id)
      //     )
      //   );
      // }
      if (
        approversUserids &&
        approversUserids.length > 0 &&
        approversUserids.includes(user_id)
      ) {
        temp = temp.concat(
          this.openLogbooks.filter(
            (x) =>
              x.stage === "approver" &&
              this.isUserPresentInLognookGroup(x.approver, user_id)
          )
        );
      }
      if (
        checkersUserids &&
        checkersUserids.length > 0 &&
        checkersUserids.includes(user_id)
      ) {
        temp = temp.concat(
          this.openLogbooks.filter(
            (x) =>
              x.stage === "checker" &&
              this.isUserPresentInLognookGroup(x.checker, user_id)
          )
        );
      }
      this.logbookTable = temp;
    },
    getData() {
      this.loading = true;
      this.openLogbooks = [];
      this.headers = [
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class:
            "font-weight-bold " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Created At",
          align: "start",
          filterable: false,
          value: "timestamp",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Logbook",
          value: "name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Stage",
          value: "stage",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Status",
          value: "status",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Started By",
          value: "fill_started_by",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Started At",
          value: "fill_start_timestamp",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Submitted At",
          value: "submitted_timestamp",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
      ];
      axios
        .get(this.$store.state.api + "getOpenManufacturingLogbookData", {
          headers: { Authorization: "Bearer " + this.$store.state.jwt },
        })
        .then((response) => {
          if (response.data.status === "success") {
            let dbData = [...response.data.data];
            let tempList = [];
            let submitted_by_present = false;
            let approved_by_present = false;
            let checked_by_present = false;
            let issued_by_present = false;
            let refilled_by_present = false;
            let count = 0;
            for (let i of dbData) {
              let temp = i;
              temp["index"] = count++;
              temp["time_remaining_for_auto_close"] = this.getTimeDifference(
                i.timestamp,
                i.closure_duration,
                i.schedule
              );
              temp["req_assignee"] = null;
              temp["timestamp"] = moment(i.timestamp).format(
                "DD-MM-YYYY HH:mm"
              );
              temp["submitted_timestamp"] = i.submitted_timestamp
                ? moment(i.submitted_timestamp).format("DD-MM-YYYY HH:mm")
                : "-";
              temp["version_date"] = i.version_date
                ? moment(i.version_date).format("DD-MM-YYYY")
                : "-";
              temp["issue_date"] = i.issue_date
                ? moment(i.issue_date).format("DD-MM-YYYY")
                : "-";
              temp["fill_start_timestamp"] = i.fill_start_timestamp
                ? moment(i.fill_start_timestamp).format("DD-MM-YYYY HH:mm")
                : "-";
              temp["fill_started_by"] = i.fill_started_by
                ? i.fill_started_by
                : "Not started";
              if (this.isLogbookFillStarted(i)) {
                tempList.push(temp);
              }
              if (i.submitted_by) {
                submitted_by_present = true;
              }
              if (i.approved_by) {
                approved_by_present = true;
              }
              if (i.checked_by) {
                checked_by_present = true;
              }
              if (i.issued_by) {
                issued_by_present = true;
              }
              if (i.refilled_by) {
                refilled_by_present = true;
              }
              if (i.auto_submitted) {
                submitted_by_present = true;
                temp["submitted_by"] = "System";
              }
            }
            if (
              submitted_by_present &&
              !this.headers.some((item) => item.value === "submitted_by")
            ) {
              this.headers.push({
                text: "Submitted By",
                value: "submitted_by",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            if (
              !this.headers.some(
                (item) => item.value === "time_remaining_for_auto_close"
              )
            ) {
              this.headers.push({
                text: "Auto Close In",
                value: "time_remaining_for_auto_close",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            if (
              approved_by_present &&
              !this.headers.some((item) => item.value === "approved_by")
            ) {
              this.headers.push({
                text: "Approved By",
                value: "approved_by",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            if (
              checked_by_present &&
              !this.headers.some((item) => item.value === "checked_by")
            ) {
              this.headers.push({
                text: "Checked By",
                value: "checked_by",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            if (
              issued_by_present &&
              !this.headers.some((item) => item.value === "issued_by")
            ) {
              this.headers.push({
                text: "Issued By",
                value: "issued_by",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            if (
              refilled_by_present &&
              !this.headers.some((item) => item.value === "refilled_by")
            ) {
              this.headers.push({
                text: "Refilled By",
                value: "refilled_by",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              });
            }
            this.openLogbooks = tempList;
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    getStatusStyle(status) {
      return {
        width: "80px", // Fixed width for all statuses
        padding: "5px",
        backgroundColor: this.getBackgroundColor(status),
        color: this.getTextColor(status),
        fontWeight: "bold",
        textAlign: "center",
        display: "inline-block", // Ensures the box respects width
        lineHeight: "20px", // Adjust to vertically center the text
      };
    },
    getBackgroundColor(status) {
      switch (status) {
        case "completed":
          return "#C8E6C9"; // Green
        case "rejected":
          return "#FFCDD2"; // Red
        case "refill":
          return "#FFECB3"; // Yellow
        case "in_process":
          return "#C5CAE9"; // Blue
        default:
          return "black"; // Default color
      }
    },
    getTextColor(status) {
      switch (status) {
        case "completed":
          return "#43A047"; // Green
        case "rejected":
          return "#E53935"; // Red
        case "refill":
          return "#FFB300"; // Yellow
        case "in_process":
          return "#3949AB"; // Blue
        default:
          return "black"; // Default color
      }
    },
  },
  watch: {
    dependenciesLoaded: {
      handler() {
        this.init();
      },
      deep: true,
    },
    openLogbooks: {
      handler() {
        if (this.openLogbooks) {
          this.createLogbooksByStage();
        }
      },
      deep: true,
    },
  },
};
</script>
