<template>
  <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      :info="info"
      v-on:close="showDismissibleAlert = false"
    />
    <v-row>
      <v-col>
        <v-form ref="orgForm" v-model="valid">
          <v-card
            :color="$store.getters.getColorPalette().backgroundColorCode"
            :dark="$store.getters.getColorPalette().isDark"
          >
            <v-card-title class="headline">
              {{ $store.getters.getTextMap().create }}
              {{ $store.getters.getTextMap().dependent }}
              {{ $store.getters.getTextMap().list }}
            </v-card-title>
            <v-card-text>
              <v-text-field
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :loading="loading"
                v-model="form.name"
                :counter="100"
                :rules="nameRules"
                :label="$store.getters.getTextMap().name"
                required
              ></v-text-field>
              <v-text-field
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :loading="loading"
                v-model="form.msg"
                :counter="100"
                :rules="nameRules"
                :label="$store.getters.getTextMap().description"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :color="$store.getters.getColorPalette().submitbtnColor"
                text
                @click="onSubmit"
              >
                {{ $store.getters.getTextMap().submit }}
              </v-btn>
              <v-btn
                :color="$store.getters.getColorPalette().submitbtnColor"
                text
                @click="onReset"
              >
                {{ $store.getters.getTextMap().cancel }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import InfoAlert from "@/components/InfoAlert";
export default {
  name: "CreateDependentList",
  props: ["list"],
  components: {
    InfoAlert,
  },
  data() {
    return {
      form: {
        name: null,
      },
      loading: false,
      info: null,
      showDismissibleAlert: false,
      valid: false,
      editMode: false,
      api: "createDependentList",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => /\S+/.test(v) || "Name is required",
        (v) =>
          (v && v.length <= 100 && v.length > 0) ||
          "Name must be less than 100 characters",
      ],
    };
  },
  mounted() {
    // console.log(this.list);
    if (this.list && this.list.list_id) {
      (this.api = "updateDependentList"), (this.editMode = true);
      this.form = Object.assign({}, this.list);
    } else {
      (this.api = "createDependentList"), (this.editMode = false);
    }
  },
  methods: {
    onSubmit() {
      this.$refs.orgForm.validate();
      if (this.valid) {
        this.loading = true;
        let d = Object.assign({}, this.form);
        if (this.editMode) {
          d["list_id"] = this.list.list_id;
        }
        axios
          .post(this.$store.state.api + this.api, d, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            this.loading = false;
            if (response.data.status == "success") {
              this.$emit("update");
              this.$store.dispatch("refreshDependentLists");
              this.$emit("close");
            }
          })
          .catch((err) => {
            console.error(err);
            this.loading = false;
          });
      }
    },
    onReset() {
      this.$refs.orgForm.reset();
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
/* .v-application .headline{
  color:white;
}
.theme--light.v-card {
   background-color: #263238;
} */
</style>
