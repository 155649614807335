<template>
  <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      :info="info"
      v-on:close="showDismissibleAlert = false"
    />

    <v-form ref="addForm" v-model="valid">
      <v-row>
        <v-col>
          <v-text-field
            :loading="loading"
            v-model="form.tag"
            :counter="100"
            :rules="nameRules"
            :label="$store.getters.getTextMap().tag_name"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :loading="loading"
            v-model="form.value"
            :counter="100"
            :rules="nameRules"
            :label="$store.getters.getTextMap().value"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn
            v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
            {{ $store.getters.getTextMap().add }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import axios from "axios";
import InfoAlert from "@/components/InfoAlert";
export default {
  name: "AddTupleToList",
  props: ["list"],
  components: {
    InfoAlert,
  },
  data() {
    return {
      form: {
        name: null,
      },
      loading: false,
      info: null,
      showDismissibleAlert: false,
      valid: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => /\S+/.test(v) || "Name is required",
        (v) =>
          (v && v.length <= 100 && v.length > 0) ||
          "Name cannot be more than 10 characters",
      ],
      numberRules: [
        (v) => !!v || "Required",
        (v) => /[0-9]+$/.test(v) || "It should be a number",
        (v) => (v && !isNaN(v)) || "It should be a valid number",
        (v) =>
          (v && v.length <= 8 && v.length > 0) ||
          "It must be less than 9 digits",
      ],
    };
  },
  methods: {
    onSubmit() {
      //evt.preventDefault();
      this.$refs.addForm.validate();
      if (this.valid) {
        this.loading = true;
        this.form.list_id = this.list.list_id;
        axios
          .post(this.$store.state.api + "addTupleToList", this.form, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status == "success" && response.data.tuple) {
              this.$store.commit("addListData", response.data.tuple);
              this.$store.dispatch("refreshListData");

              this.info = "Data added";
              this.showDismissibleAlert = true;
              this.loading = false;
              this.onReset();
              this.$emit("close");
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
            //this.onReset()
            this.loading = false;
          })
          .catch((error) => {
            this.onReset();
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
          });
      }
    },
    onReset() {
      this.$refs.addForm.reset();
      this.$emit("close");
    },
  },
};
</script>
