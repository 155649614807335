<template>
  <v-container fluid
    ><v-row no-gutters class="ma-0 pa-0">
      <v-col>
        <ManufacturingLogbookHistoricalPanel />
      </v-col> </v-row
  ></v-container>
</template>
<script>
import ManufacturingLogbookHistoricalPanel from "@/components/panels/manufacturing/logbooks/historical/ManufacturingLogbookHistoricalPanel";
export default {
  name: "ManufacturingLogbookHistoricalPage",
  components: {
    ManufacturingLogbookHistoricalPanel,
  },
};
</script>
