var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),(_vm.downloadInProcess)?_c('div',[_c('ChecklistDataPDFTemplate',{attrs:{"checklistFormDetails":_vm.checklistFormDetails,"checklist":_vm.checklist},on:{"success":function($event){_vm.downloadInProcess = false}}})],1):_c('div',[(!_vm.refillRequest)?_c('div',[(_vm.checklist.table_second)?_c('div',_vm._l((_vm.checklistFormDetails),function(item,index){return _c('v-row',{key:index},[(!_vm.loading)?_c('v-col',{staticClass:"ma-0 pa-1"},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_vm._e(),_c('v-card',{staticStyle:{"border-radius":"12px"},attrs:{"flat":"","outlined":"","dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().background2ColorCode}},[_c('v-divider'),_c('v-card-subtitle',{staticClass:"text-subtitle-1",style:({ color: _vm.$store.getters.getColorPalette().textColor })},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-body-1 font-weight-bold",style:({
                        color: _vm.$store.getters.getColorPalette().accentCode,
                      })},[_vm._v(" "+_vm._s(item.key_text)+" ")])]),_c('v-col',{attrs:{"align":"right"}},[(item.entry_type === 'image')?_c('div',[_c('v-img',{attrs:{"src":item.imageData,"alt":item.key_text,"max-width":"100px","max-height":"100px","contain":""}})],1):(item.entry_type === 'check')?_c('div',[(item.data == 'true')?_c('v-icon',{staticClass:"mr-3",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":"green"}},[_vm._v("mdi-check-outline")]):_c('v-icon',{staticClass:"mr-3",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":"red"}},[_vm._v("mdi-close-outline")])],1):_c('div',[_c('span',{style:({
                          color: _vm.$store.getters.getColorPalette().accentCode,
                        })},[_vm._v(" "+_vm._s(item.data)+" ")])])])],1)],1)],1)],1):_vm._e()],1)}),1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-simple-table',{style:({
              'background-color':
                _vm.$store.getters.getColorPalette().backgroundColorCode,
            }),attrs:{"fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.checklist.table_json.headers),function(item,index){return _c('th',{key:index,staticClass:"text-left",class:_vm.$store.getters.getColorPalette().foregroundColorName,attrs:{"color":_vm.$store.getters.getColorPalette().accentCode}},[_c('strong',[_vm._v(_vm._s(item))])])}),0)]),_c('v-divider'),(!_vm.loading && _vm.checklist.table_json)?_c('tbody',{style:({
                  'background-color':
                    _vm.$store.getters.getColorPalette().backgroundColorCode,
                })},_vm._l((_vm.checklist.table_json.rows),function(item,itemIndex){return _c('tr',{key:itemIndex,style:({
                    'background-color':
                      _vm.$store.getters.getColorPalette().background1ColorCode,
                  })},_vm._l((item),function(x,index){return _c('td',{key:index,staticClass:"text-left"},[(x.type === 'static')?_c('div',[_vm._v(" "+_vm._s(x.value)+" ")]):(x.value.type === 'check')?_c('div',[(x.value.data)?_c('v-icon',{staticClass:"mr-3",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":"green"}},[_vm._v("mdi-check-outline")]):_c('v-icon',{staticClass:"mr-3",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":"red"}},[_vm._v("mdi-close-outline")])],1):_c('div',[_vm._v(" "+_vm._s(x.value.data)+" ")])])}),0)}),0):_vm._e()]},proxy:true}],null,false,987835114)})],1)],1),(!_vm.checklist.table_second)?_c('div',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_vm._e(),_vm._l((_vm.checklistFormDetails),function(item,index){return _c('v-row',{key:index},[(!_vm.loading)?_c('v-col',{staticClass:"ma-0 pa-1"},[_c('v-card',{staticStyle:{"border-radius":"12px"},attrs:{"flat":"","outlined":"","dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().background2ColorCode}},[_c('v-divider'),_c('v-card-subtitle',{staticClass:"text-subtitle-1",style:({ color: _vm.$store.getters.getColorPalette().textColor })},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-body-1 font-weight-bold",style:({
                        color: _vm.$store.getters.getColorPalette().accentCode,
                      })},[_vm._v(" "+_vm._s(item.key_text)+" ")])]),_c('v-col',{attrs:{"align":"right"}},[(item.entry_type === 'image' && item.imageData)?_c('div',[_c('v-img',{attrs:{"src":item.imageData,"alt":item.key_text,"max-width":"500px","max-height":"500px","contain":""}})],1):(item.entry_type === 'check')?_c('div',[(item.data == 'true')?_c('v-icon',{staticClass:"mr-3",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":"green"}},[_vm._v("mdi-check-outline")]):_c('v-icon',{staticClass:"mr-3",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":"red"}},[_vm._v("mdi-close-outline")])],1):_c('div',[_c('span',{style:({
                          color: _vm.$store.getters.getColorPalette().accentCode,
                        })},[_vm._v(" "+_vm._s(item.data)+" ")])])])],1)],1)],1)],1):_vm._e()],1)})],2):_vm._e(),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(
          !_vm.loading &&
          _vm.checklist &&
          (_vm.checklist.status === 'completed' ||
            _vm.checklist.status === 'rejected')
        )?_c('v-btn',{staticClass:"mt-5",attrs:{"color":_vm.$store.getters.getColorPalette().downloadbtnColor,"small":""},on:{"click":function($event){_vm.downloadInProcess = true}}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().download))]):_vm._e(),(!_vm.readOnly)?_c('v-row',[_c('v-col',{attrs:{"cols":"2","align-self":"end"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":_vm.$store.getters.getTextMap().remarks},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}})],1),_c('v-col',{attrs:{"align":"right","align-self":"center"}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-btn',{staticClass:"mx-1",attrs:{"color":_vm.$store.getters.getColorPalette().cancelbtnColor,"small":""},on:{"click":function($event){return _vm.moveStage(true)}}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().reject))]):_vm._e(),(!_vm.loading)?_c('v-btn',{staticClass:"mx-1",attrs:{"color":_vm.$store.getters.getColorPalette().downloadbtnColor,"small":""},on:{"click":function($event){_vm.refillRequest = true}}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().refill_request))]):_vm._e(),(!_vm.loading)?_c('v-btn',{attrs:{"color":_vm.$store.getters.getColorPalette().submitbtnColor,"small":""},on:{"click":function($event){return _vm.moveStage(false)}}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().accept)+" And "+_vm._s(_vm.$store.getters.getTextMap().move_to_next_stage))]):_vm._e()],1)],1):_vm._e()],1):(
        _vm.$store.state.user && _vm.$store.getters.getAccessByLevels(['logs'])
      )?_c('div',[_c('v-row',[_c('v-col',[_c('h2',{style:({ color: _vm.$store.getters.getColorPalette().accentCode })},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().refill_request)+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-form',{ref:"refillForm",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[(!_vm.loading)?_c('v-select',{attrs:{"items":_vm.userList,"label":_vm.$store.getters.getTextMap().assignee,"dense":"","outlined":"","item-text":"label","item-value":"value","menu-props":{ dark: _vm.$store.getters.getColorPalette().isDark },"rules":[_vm.rules.required],"dark":_vm.$store.getters.getColorPalette().isDark},model:{value:(_vm.req_assignee),callback:function ($$v) {_vm.req_assignee=$$v},expression:"req_assignee"}}):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"right"}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-btn',{staticClass:"mx-1",attrs:{"color":_vm.$store.getters.getColorPalette().cancelbtnColor,"small":""},on:{"click":function($event){_vm.refillRequest = false}}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().cancel))]):_vm._e(),(!_vm.loading)?_c('v-btn',{attrs:{"color":_vm.$store.getters.getColorPalette().submitbtnColor,"small":""},on:{"click":_vm.refillSubmit}},[_vm._v(_vm._s(_vm.$store.getters.getTextMap().submit))]):_vm._e()],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }