<template>
  <v-container fluid class="no-padding bordered-container">
    <v-row no-gutters class="ma-0 pa-0">
      <v-col>
        <ManufacturingLogbookReferenceSheetPanel />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ManufacturingLogbookReferenceSheetPanel from "@/components/panels/manufacturing/logbooks/configurations/ManufacturingLogbookReferenceSheetPanel";
export default {
  name: "ManufacturingLogbookConfigurationsPage",
  components: {
    ManufacturingLogbookReferenceSheetPanel,
  },
};
</script>
