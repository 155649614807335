<template>
  <v-container
    fluid
    :style="{
      'background-color': $store.getters.getColorPalette().backgroundColorCode,
    }"
  >
    <v-row>
      <v-col cols="12">
        <DependentListsTable />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DependentListsTable from "@/components/lists/derived/DependentListsTable";
export default {
  name: "DependentListPage",
  components: {
    DependentListsTable,
  },
};
</script>
