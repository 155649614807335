<template>
  <v-container class="align-center text-center">
    <v-row justify="center">
      <v-col cols="12">
        <img src="/images/lock.svg" alt="Access Denied" class="access-image" />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">
        <h1
          style="color: #d32f2f"
          class="text-h4 font-weight-bold text-error mb-4"
        >
          Access Denied! Payment Overdue
          <v-icon class="ml-5" large color="red">mdi-cancel</v-icon>
        </h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">
        <v-btn
          @click="redirect"
          variant="outlined"
          color="primary"
          size="large"
          class="px-6 py-3"
        >
          {{ $store.getters.getTextMap().back }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AccessDeniedPage",
  methods: {
    redirect() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.access-image {
  max-width: 150px;
  height: auto;
  margin-bottom: 20px;
}
</style>
