<template>
  <v-row justify-end>
    <v-col align="right">
      <v-btn
        class="list"
        :background-color="
          $store.getters.getColorPalette().background1ColorCode
        "
        :color="$store.getters.getColorPalette().btnborderColorCode"
        small
        :dark="$store.getters.getColorPalette().isDark"
        outlined
        @click="dialog = !dialog"
      >
        {{ $store.getters.getTextMap().create }}
        {{ $store.getters.getTextMap().dependent }}
        {{ $store.getters.getTextMap().list }}
        <v-icon
          right
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().deletebtnColor"
          >mdi-plus
        </v-icon>
      </v-btn>
      <v-dialog v-model="dialog" max-width="290">
        <CreateDependentList v-on:close="onClose" />
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import CreateDependentList from "@/components/crud_components/derived/CreateDependentList";
export default {
  name: "CreateDependentListsModal",
  components: {
    CreateDependentList,
  },
  data() {
    return {
      dialog: false,
      valid: false,
    };
  },
  methods: {
    onClose() {
      this.dialog = false;
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.list {
  border: 3px solid;
  border-radius: 20px;
}
.v-application .headline {
  color: white;
}
.theme--light.v-card {
  background-color: #263238;
}
</style>
