var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),(!_vm.dependenciesLoaded)?_c('span',[_c('LoadingLinear',{attrs:{"text":"Logbooks are loading..."}})],1):_c('span',[_c('div',{style:({ display: 'flex', alignItems: 'center' })},[_c('h2',{style:({ color: _vm.$store.getters.getColorPalette().accentCode })},[_vm._v(" Refill Logbooks ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"background-color":_vm.$store.getters.getColorPalette().background1ColorCode,"color":_vm.$store.getters.getColorPalette().accentCode,"dark":_vm.$store.getters.getColorPalette().isDark,"outlined":"","small":"","text":""},on:{"click":_vm.init}},[_c('v-icon',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().btnborderColorCode}},[_vm._v("mdi-refresh")])],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto mt-5",attrs:{"type":"text"}}):_vm._e(),(_vm.logbookTable && _vm.logbookTable.length > 0)?_c('v-col',[_c('v-divider',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}}),_c('v-data-table',{staticStyle:{"background":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.logbookTable,"item-key":"index","search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(_vm.getStatusStyle(item.status))},[_vm._v(" "+_vm._s(_vm.statusNameMap[item.status])+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status === 'refill' && item.stage === 'maker')?_c('div',[(_vm.isUserFromMakerGroup())?_c('v-btn',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"small":"","outlined":"","text":""},on:{"click":function($event){return _vm.startLogbookFilling(item)}}},[_vm._v(" Start Refill ")]):_vm._e()],1):_vm._e()]}}],null,false,2564255690)}),_c('v-divider',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}})],1):(!_vm.loading)?_c('v-col',[_c('div',{style:([
            {
              padding: '10px',
              display: 'flex',
              'justify-content': 'center',
              color: _vm.$store.getters.getColorPalette().accentCode,
            } ])},[_c('v-icon',{staticClass:"mr-3",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}},[_vm._v("mdi-database-remove-outline")]),_c('h3',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().no_data_available))])],1)]):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-dialog',{style:({
          border: '2px solid ' + _vm.$store.getters.getColorPalette().accentCode,
        }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ManufacturingLogbookMakerForm',{attrs:{"logbookData":_vm.logbookData,"readOnly":false},on:{"closeForm":_vm.closeDialog,"submitted":_vm.getData}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }