var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.list.name ? _vm.list.name : _vm.list.list_id)+" "),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-spacer'),(
          _vm.$store.state.user &&
          _vm.$store.state.settingMode &&
          _vm.$store.getters.getAccessByLevels(['engineeraccess'])
        )?_c('span',[(
            _vm.$store.state.user &&
            _vm.$store.state.settingMode &&
            _vm.$store.getters.getAccessByLevels(['betaaccess', 'engineeraccess'])
          )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){_vm.editMode = !_vm.editMode}}},[_vm._v(" mdi-pencil ")]):_vm._e(),_c('DeleteConfirmation',{attrs:{"title":"Delete Confirmation","description":"Are you sure you want to delete this?"},on:{"confirm":function($event){return _vm.deleteList()}}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete ")])],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-subtitle',[(_vm.editMode == true)?_c('v-row',[_c('v-col',[_c('CreateDependentList',{attrs:{"list":_vm.list},on:{"close":function($event){_vm.editMode = false},"update":function($event){return _vm.$emit('update')}}})],1)],1):_vm._e(),(_vm.loading)?_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e()],1),_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e()],1)],1):_vm._e(),(
          _vm.$store.state.user &&
          _vm.$store.state.settingMode &&
          _vm.$store.getters.getAccessByLevels(['engineeraccess'])
        )?_c('v-row',[_c('v-col',[_c('span',[(_vm.addDialog)?_c('AddTupleToDependentList',{attrs:{"list":_vm.list}}):_vm._e()],1)])],1):_vm._e(),_vm._l((_vm.getKeys()),function(key,index){return _c('v-row',{key:index},[_c('v-col',[_c('v-card',{style:({
              border:
                '1px solid ' +
                _vm.$store.getters.getColorPalette().panelBorderColorCode,
            }),attrs:{"outlined":"","dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().backgroundColorCode}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(key))]),_c('span',[_c('DeleteConfirmation',{attrs:{"title":"Delete Confirmation","description":"Are you sure you want to delete this?"},on:{"confirm":function($event){return _vm.deleteQueryKeyFromList(key)}}},[(!_vm.loading)?_c('v-btn',{attrs:{"text":"","outlined":"","small":""}},[_vm._v(" Delete "),_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1)],1)]),_c('v-divider',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}}),_c('v-simple-table',{attrs:{"height":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_c('strong',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().tag))])]),_c('th',{staticClass:"text-left"},[_c('strong',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().value))])]),(
                        _vm.$store.state.user &&
                        _vm.$store.state.settingMode &&
                        _vm.$store.getters.getAccessByLevels(['engineeraccess'])
                      )?_c('th',{staticClass:"text-left"},[_c('strong',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().actions))])]):_vm._e()])]),_c('tbody',_vm._l((_vm.getTuples(key)),function(item){return _c('tr',{key:item.value},[_c('td',[_vm._v(_vm._s(item.tag))]),_c('td',[_vm._v(_vm._s(item.value))]),(
                        _vm.$store.state.user &&
                        _vm.$store.state.settingMode &&
                        _vm.$store.getters.getAccessByLevels(['engineeraccess'])
                      )?_c('td',[_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteTupleFromList(item, key)}}},[_vm._v("mdi-delete")])],1):_vm._e()])}),0)]},proxy:true}],null,true)}),_c('v-divider',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}})],1)],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }