<template>
  <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <!-- <v-row v-if="this.$store.state.user && this.$store.getters.getAccessByLevels(['alphaaccess'])">
            <v-col>
                <ImageCapturingComponent
                    @imageCaptured="handleImageProcessing"
                    :fieldData="{ key: 'user_image' }"
                />
            </v-col>    
        </v-row> -->
    <div v-if="startScanning && !disableForm">
      <v-dialog
        v-model="startScanning"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card
          flat
          outlined
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().background2ColorCode"
        >
          <QRCodeScanner
            :fieldData="fieldData"
            v-on:qrData="addQRData"
            v-on:goBack="toggleScan"
          />
        </v-card>
      </v-dialog>
    </div>
    <div v-if="startImageUpload && !disableForm">
      <v-dialog
        v-model="startImageUpload"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card
          flat
          outlined
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().background2ColorCode"
        >
          <OCRComponent
            :fieldData="fieldData"
            v-on:ocrData="addOCRData"
            v-on:goBack="toggleImageComponent"
          />
        </v-card>
      </v-dialog>
    </div>
    <div v-if="startImageCapturing && !disableForm">
      <v-dialog
        v-model="startImageCapturing"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card
          flat
          outlined
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().background2ColorCode"
        >
          <ImageComponent
            :conversionType="'base64'"
            :fieldData="fieldData"
            :compressImage="true"
            v-on:imageData="addCapturedImageData"
            v-on:goBack="toggleImageCapture"
          />
        </v-card>
      </v-dialog>
    </div>
    <v-row class="mb-5" v-if="checklist && !checklist.fill_started">
      <v-col>
        <v-btn
          v-if="!loading"
          class="mr-1"
          :color="$store.getters.getColorPalette().submitbtnColor"
          small
          @click="startChecklistFilling"
          >{{
            $store.getters.getTextMap().start +
            " " +
            $store.getters.getTextMap().filling
          }}</v-btn
        >
      </v-col>
    </v-row>
    <v-form ref="dynamicForm" v-model="isFormValid">
      <div v-if="checklist.table_second">
        <v-simple-table
          dense
          :style="{
            'background-color':
              $store.getters.getColorPalette().background1ColorCode,
            height: '100%',
          }"
        >
          <template v-slot:default>
            <!-- <v-divider></v-divider> -->
            <tbody
              :style="{
                'background-color':
                  $store.getters.getColorPalette().backgroundColorCode,
              }"
              v-if="!loading && tableJsonForm"
            >
              <tr
                v-for="(item, index) in formFields"
                :key="index"
                :style="{
                  'background-color':
                    $store.getters.getColorPalette().backgroundColorCode,
                }"
              >
                <td
                  class="text-left"
                  :color="$store.getters.getColorPalette().accentCode"
                >
                  <span class="pa-1">{{ item.key_text }}:</span>
                </td>
                <td
                  class="text-right align-right"
                  :color="$store.getters.getColorPalette().accentCode"
                >
                  <span class="" v-if="item.entry_type === 'text' && !loading">
                    <v-text-field
                      small
                      dense
                      outlined
                      class="py-0 my-0"
                      :disabled="disableForm"
                      v-model="formValues[item.key_text]"
                      :label="item.key_text"
                      :required="item.required"
                      :rules="item.required ? [rules.required] : []"
                    ></v-text-field>
                  </span>
                  <span
                    class=""
                    v-else-if="item.entry_type === 'list_text' && !loading"
                  >
                    <v-select
                      v-if="!loading"
                      :placeholder="item.key_text"
                      v-model="formValues[item.key_text]"
                      :items="listDropdown(item.meta.list_id)"
                      :disabled="disableForm"
                      item-text="label"
                      item-value="value"
                      :rules="item.required ? [rules.required] : []"
                      outlined
                      dense
                    >
                    </v-select>
                  </span>
                  <span v-else-if="item.entry_type === 'ocr' && !loading">
                    <span class="pa-5">
                      {{ formValues[item.key_text] }}
                    </span>
                    <span class="pa-5" v-if="!startImageUpload">
                      <v-btn
                        :color="
                          $store.getters.getColorPalette().downloadbtnColor
                        "
                        outlined
                        small
                        :disabled="disableForm"
                        @click="
                          toggleImageComponent({ ...item, type: 'key_value' })
                        "
                        >Scan Image</v-btn
                      >
                    </span>
                    <!-- <div v-if="startScanning&& !disableForm">
                                                <QRCodeScanner v-on:qrData="addQRData(item,$event)" v-on:goBack="startScanning=false" />
                                            </div> -->
                    <!-- <div v-else-if="!formValues[item.key_text]&&!startScanning && disableForm" class="pl-2 my-3">
                                                * Please click on "Start Filling" to scan the QR Code
                                            </div> -->
                  </span>
                  <span v-else-if="item.entry_type === 'qrtext' && !loading">
                    <span v-if="!startScanning" class="mb-1">
                      <v-btn
                        :color="
                          $store.getters.getColorPalette().downloadbtnColor
                        "
                        outlined
                        small
                        :disabled="disableForm"
                        @click="toggleScan({ ...item, type: 'key_value' })"
                        >Scan QR</v-btn
                      >
                    </span>
                    <!-- <div v-if="startScanning&& !disableForm">
                                            <QRCodeScanner v-on:qrData="addQRData(item,$event)" v-on:goBack="startScanning=false" />
                                        </div> -->
                    <!-- <div v-else-if="!formValues[item.key_text]&&!startScanning && disableForm" class="pl-2 my-3">
                                            * Please click on "Start Filling" to scan the QR Code
                                        </div> -->
                    <span v-else class="pa-5">
                      {{ formValues[item.key_text] }}
                    </span>
                  </span>
                  <!-- 
                                    <v-file-input
                                        dense
                                        outlined
                                        v-else-if="item.entry_type === 'image'  && !loading"
                                        v-model="formValues[item.key_text]"
                                        :label="item.key_text"
                                        :required="item.required"
                                        :rules="item.required ? [rules.required] : []"
                                    ></v-file-input> -->
                  <span v-else-if="item.entry_type === 'image' && !loading">
                    <!-- <v-file-input
                      dense
                      outlined
                      :disabled="disableForm"
                      :label="`Upload ${item.key_text}`"
                      v-model="fieldFiles[item.key_text]"
                      accept="image/*"
                      @change="validateAndCompressImage(item.key_text)"
                    ></v-file-input> -->
                    <span>
                      <v-btn
                        :color="
                          $store.getters.getColorPalette().downloadbtnColor
                        "
                        outlined
                        small
                        :disabled="disableForm"
                        @click="
                          toggleImageCapture({ ...item, type: 'key_value' })
                        "
                        >Upload
                      </v-btn>
                    </span>
                    <v-skeleton-loader
                      v-if="loading"
                      type="text"
                    ></v-skeleton-loader>
                    <span v-if="formValues[item.key_text] && !loading">
                      <h4>{{ item.key_text }} Preview:</h4>
                      <v-img
                        :src="formValues[item.key_text]"
                        alt="Preview"
                        max-width="500px"
                        max-height="500px"
                      />
                    </span>
                  </span>
                  <span v-else-if="item.entry_type === 'check' && !loading">
                    <v-checkbox
                      small
                      dense
                      outlined
                      :disabled="disableForm"
                      v-model="formValues[item.key_text]"
                      :required="item.required"
                    ></v-checkbox>
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <!-- :label="item.key_text" -->

      <v-row no-gutters class="mb-2">
        <v-col>
          <v-simple-table
            fixed-header
            dense
            :style="{
              'background-color':
                $store.getters.getColorPalette().backgroundColorCode,
            }"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(header, index) in tableJsonForm.headers"
                    :key="index"
                    class="text-left"
                    :class="
                      $store.getters.getColorPalette().foregroundColorName
                    "
                    :color="$store.getters.getColorPalette().accentCode"
                  >
                    <strong>{{ header }}</strong>
                  </th>
                </tr>
              </thead>
              <!-- <v-divider></v-divider> -->
              <tbody
                :style="{
                  'background-color':
                    $store.getters.getColorPalette().backgroundColorCode,
                }"
                v-if="!loading && tableJsonForm"
              >
                <tr
                  v-for="(row, rowIndex) in tableJsonForm.rows"
                  :key="rowIndex"
                  :style="{
                    'background-color':
                      $store.getters.getColorPalette().background1ColorCode,
                  }"
                >
                  <td
                    class="text-left"
                    v-for="(cell, cellIndex) in row"
                    :key="cellIndex"
                  >
                    <!-- Handle Static Type -->
                    <span v-if="cell.type === 'static'">
                      {{ cell.value }}
                    </span>
                    <!-- Handle Input Types -->
                    <div v-else-if="cell.type === 'input'">
                      <!-- Checkbox -->
                      <v-checkbox
                        v-if="cell.value.type === 'check'"
                        v-model="
                          tableJsonForm.rows[rowIndex][cellIndex].value.data
                        "
                        hide-details
                        :disabled="disableForm"
                        :color="$store.getters.getColorPalette().accentCode"
                      ></v-checkbox>
                      <!-- Text Box -->
                      <v-text-field
                        v-else-if="cell.value.type === 'text'"
                        v-model="
                          tableJsonForm.rows[rowIndex][cellIndex].value.data
                        "
                        dense
                        :disabled="disableForm"
                        hide-details
                        class="pa-0"
                      >
                      </v-text-field>
                      <v-select
                        v-if="cell.value.type === 'list_text' && !loading"
                        v-model="
                          tableJsonForm.rows[rowIndex][cellIndex].value.data
                        "
                        :items="listDropdown(cell.value.meta.list_id)"
                        :disabled="disableForm"
                        item-text="label"
                        item-value="value"
                        outlined
                        dense
                      >
                      </v-select>
                      <div v-if="cell.value.type === 'qrtext' && !loading">
                        <span class="pa-5">
                          {{
                            tableJsonForm.rows[rowIndex][cellIndex].value.data
                          }}
                        </span>
                        <span v-if="!startScanning" class="ma-2">
                          <v-btn
                            :color="
                              $store.getters.getColorPalette().downloadbtnColor
                            "
                            outlined
                            small
                            :disabled="disableForm"
                            @click="
                              toggleScan({ rowIndex, cellIndex, type: 'table' })
                            "
                            >Scan QR</v-btn
                          >
                        </span>
                      </div>
                      <div v-if="cell.value.type === 'ocr' && !loading">
                        <!-- <div v-if="!disableForm" class="pa-4">
                                                {{ item.key_text }}
                                            </div> -->
                        <span class="pa-5">
                          {{
                            tableJsonForm.rows[rowIndex][cellIndex].value.data
                          }}
                        </span>
                        <span v-if="!startScanning" class="ma-2">
                          <v-btn
                            :color="
                              $store.getters.getColorPalette().downloadbtnColor
                            "
                            outlined
                            small
                            :disabled="disableForm"
                            @click="
                              toggleImageComponent({
                                rowIndex,
                                cellIndex,
                                type: 'table',
                              })
                            "
                            >Scan Image</v-btn
                          >
                        </span>
                        <!-- <div v-if="startScanning&& !disableForm">
                                                <QRCodeScanner v-on:qrData="addQRDataToTable(rowIndex,cellIndex,$event)" v-on:goBack="startScanning=false" />
                                            </div> -->
                        <!-- <span v-else-if="!tableJsonForm.rows[rowIndex][cellIndex].value.data&&!startScanning && disableForm" class="pl-2 my-3">
                                                * Please click on "Start Filling" to scan the QR Code
                                            </span> -->
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <div v-if="!checklist.table_second">
        <!-- <v-row no-gutters  >
                <v-col cols="12"> -->
        <v-simple-table
          dense
          :style="{
            'background-color':
              $store.getters.getColorPalette().background1ColorCode,
            height: '100%',
          }"
        >
          <template v-slot:default>
            <!-- <v-divider></v-divider> -->
            <tbody
              :style="{
                'background-color':
                  $store.getters.getColorPalette().backgroundColorCode,
              }"
              v-if="!loading && tableJsonForm"
            >
              <tr
                v-for="(item, index) in formFields"
                :key="index"
                :style="{
                  'background-color':
                    $store.getters.getColorPalette().backgroundColorCode,
                }"
              >
                <td
                  class="text-left"
                  :color="$store.getters.getColorPalette().accentCode"
                >
                  <span class="pa-1">{{ item.key_text }}:</span>
                </td>
                <td
                  class="text-right align-right"
                  :color="$store.getters.getColorPalette().accentCode"
                >
                  <span class="" v-if="item.entry_type === 'text' && !loading">
                    <v-text-field
                      small
                      dense
                      outlined
                      class="py-0 my-0"
                      :disabled="disableForm"
                      v-model="formValues[item.key_text]"
                      :label="item.key_text"
                      :required="item.required"
                      :rules="item.required ? [rules.required] : []"
                    ></v-text-field>
                  </span>
                  <span
                    class=""
                    v-else-if="item.entry_type === 'list_text' && !loading"
                  >
                    <v-select
                      v-if="!loading"
                      :placeholder="item.key_text"
                      v-model="formValues[item.key_text]"
                      :items="listDropdown(item.meta.list_id)"
                      :disabled="disableForm"
                      item-text="label"
                      item-value="value"
                      :rules="item.required ? [rules.required] : []"
                      outlined
                      dense
                    >
                    </v-select>
                  </span>
                  <span v-else-if="item.entry_type === 'ocr' && !loading">
                    <span class="pa-5">
                      {{ formValues[item.key_text] }}
                    </span>
                    <span class="pa-5" v-if="!startImageUpload">
                      <v-btn
                        :color="
                          $store.getters.getColorPalette().downloadbtnColor
                        "
                        outlined
                        small
                        :disabled="disableForm"
                        @click="
                          toggleImageComponent({ ...item, type: 'key_value' })
                        "
                        >Scan Image</v-btn
                      >
                    </span>
                    <!-- <div v-if="startScanning&& !disableForm">
                                                <QRCodeScanner v-on:qrData="addQRData(item,$event)" v-on:goBack="startScanning=false" />
                                            </div> -->
                    <!-- <div v-else-if="!formValues[item.key_text]&&!startScanning && disableForm" class="pl-2 my-3">
                                                * Please click on "Start Filling" to scan the QR Code
                                            </div> -->
                  </span>
                  <span v-else-if="item.entry_type === 'qrtext' && !loading">
                    <span class="pa-5">
                      {{ formValues[item.key_text] }}
                    </span>
                    <span v-if="!startScanning" class="mb-1">
                      <v-btn
                        :color="
                          $store.getters.getColorPalette().downloadbtnColor
                        "
                        outlined
                        small
                        :disabled="disableForm"
                        @click="toggleScan({ ...item, type: 'key_value' })"
                        >Scan QR</v-btn
                      >
                    </span>
                    <!-- <div v-if="startScanning&& !disableForm">
                                            <QRCodeScanner v-on:qrData="addQRData(item,$event)" v-on:goBack="startScanning=false" />
                                        </div> -->
                    <!-- <div v-else-if="!formValues[item.key_text]&&!startScanning && disableForm" class="pl-2 my-3">
                                            * Please click on "Start Filling" to scan the QR Code
                                        </div> -->
                  </span>
                  <!-- 
                                    <v-file-input
                                        dense
                                        outlined
                                        v-else-if="item.entry_type === 'image'  && !loading"
                                        v-model="formValues[item.key_text]"
                                        :label="item.key_text"
                                        :required="item.required"
                                        :rules="item.required ? [rules.required] : []"
                                    ></v-file-input> -->
                  <span v-else-if="item.entry_type === 'image' && !loading">
                    <!-- <v-file-input
                      dense
                      outlined
                      :disabled="disableForm"
                      :label="`Upload ${item.key_text}`"
                      v-model="fieldFiles[item.key_text]"
                      accept="image/*"
                      @change="validateAndCompressImage(item.key_text)"
                    ></v-file-input> -->
                    <span>
                      <v-btn
                        :color="
                          $store.getters.getColorPalette().downloadbtnColor
                        "
                        outlined
                        small
                        :disabled="disableForm"
                        @click="
                          toggleImageCapture({ ...item, type: 'key_value' })
                        "
                        >Upload
                      </v-btn>
                    </span>
                    <v-skeleton-loader
                      v-if="loading"
                      type="text"
                    ></v-skeleton-loader>
                    <span v-if="formValues[item.key_text] && !loading">
                      <h4>{{ item.key_text }} Preview:</h4>
                      <v-img
                        :src="formValues[item.key_text]"
                        alt="Preview"
                        max-width="500px"
                        max-height="500px"
                      />
                    </span>
                  </span>
                  <span v-else-if="item.entry_type === 'check' && !loading">
                    <v-checkbox
                      small
                      dense
                      outlined
                      :disabled="disableForm"
                      v-model="formValues[item.key_text]"
                      :required="item.required"
                    ></v-checkbox>
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- </v-col>
            </v-row> -->
      </div>
      <v-row>
        <v-col align="right">
          <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
          <v-btn
            v-if="!loading"
            class="mr-1"
            :color="$store.getters.getColorPalette().submitbtnColor"
            small
            @click="submitForm(true)"
            >{{ $store.getters.getTextMap().submit_move_next_stage }}</v-btn
          >
          <v-btn
            v-if="!loading"
            :color="$store.getters.getColorPalette().downloadbtnColor"
            small
            @click="submitForm(false)"
            >{{ $store.getters.getTextMap().submit_draft }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<!-- <span v-if="base64Images[item.key_text]">
                      <h4>{{ item.key_text }} Preview:</h4>
                      <img
                        :src="base64Images[item.key_text]"
                        alt="Preview"
                        style="max-width: 100%; height: auto"
                      />
                    </span> -->
<script>
import InfoAlert from "@/components/InfoAlert.vue";
import axios from "axios";
// import extractTextFromImage from '@/utillities/QCRUtillity';
import QRCodeScanner from "@/components/reusable_components/QRCodeScanner.vue";
import OCRComponent from "@/components/reusable_components/OCRComponent.vue";
import ImageComponent from "@/components/reusable_components/ImageComponent.vue";
// import ImageCapturingComponent from '@/components/reusable_components/ImageCapturingComponent.vue';
export default {
  name: "ChecklistMakerForm",
  props: ["checklist"],
  components: {
    InfoAlert,
    QRCodeScanner,
    OCRComponent,
    ImageComponent,
    // ImageCapturingComponent
    // QRCodeReader
  },
  data() {
    return {
      info: "",
      showDismissibleAlert: false,
      loading: false,
      formFields: [],
      disableForm: false,
      isFormValid: false,
      formValues: {},
      tableJsonForm: {},
      fieldFiles: {}, // Stores selected files
      base64Images: {}, // Stores base64 values
      maxFileSize: 100 * 1024, // Limit file size to 2MB
      maxImageWidth: 800, // Compress to max width
      maxImageHeight: 800, // Compress to max height,
      fieldData: null,
      rules: {
        required: (value) => !!value || "This field is required",
      },
      startScanning: false,
      startImageUpload: false,
      startImageCapturing: false,
    };
  },

  mounted() {
    // console.log(this.checklist);
    if (
      this.checklist &&
      this.checklist.checklist_id &&
      this.checklist.template_id
    ) {
      if (!this.checklist.fill_started) {
        this.disableForm = true;
      }
      this.getChecklistTemplate();
      //   if (this.checklist.table_json) {
      //     this.tableJsonForm = this.checklist.table_json;
      //   } else {
      //     this.getChecklistTableJsonForm();
      // }
      this.getChecklistTableJsonForm();
    }
  },
  methods: {
    listDropdown(list_id) {
      let op = [];
      let lists = this.$store.getters.getListDataById(list_id);
      if (lists && lists.length > 0) {
        for (let i of lists) {
          op.push({ label: i.tag, value: i.value });
        }
      }
      return op;
    },
    // handleImageProcessing(returnData) {
    //     const imageData = returnData.imageData; // Base64 image
    //     console.log("Captured Image Data:", imageData);

    //     // Convert Base64 to Blob for OCR processing
    //     const blob = this.base64ToBlob(imageData);
    //     this.handleQCR(blob);
    // },
    // base64ToBlob(base64) {
    //     const byteString = atob(base64.split(",")[1]);
    //     const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
    //     const ab = new ArrayBuffer(byteString.length);
    //     const ia = new Uint8Array(ab);

    //     for (let i = 0; i < byteString.length; i++) {
    //     ia[i] = byteString.charCodeAt(i);
    //     }

    //     return new Blob([ab], { type: mimeString });
    // },
    // async handleQCR(imageBlob) {
    //     try {
    //         const extractedText = await extractTextFromImage(imageBlob);
    //         console.log("Extracted Text:", extractedText.data.text);
    //         // Perform further actions with the extracted text
    //     } catch (error) {
    //         console.error("OCR Failed:", error);
    //     }
    // },
    toggleScan(item) {
      this.fieldData = null;
      if (item) {
        this.fieldData = item;
      }
      this.startScanning = !this.startScanning;
    },
    toggleImageCapture(item) {
      this.fieldData = null;
      if (item) {
        this.fieldData = item;
      }
      this.startImageCapturing = !this.startImageCapturing;
    },
    toggleImageComponent(item) {
      this.fieldData = null;
      if (item) {
        this.fieldData = item;
      }
      this.startImageUpload = !this.startImageUpload;
    },
    addQRData(value) {
      if (value) {
        if (value.type === "table") {
          this.tableJsonForm.rows[value.rowIndex][value.cellIndex].value.data =
            value.qrData;
        } else {
          this.formValues[value.key_text] = value.qrData;
        }
      }
      this.fieldData = null;
      this.startScanning = false;
    },
    addOCRData(value) {
      if (value) {
        if (value.type === "table") {
          this.tableJsonForm.rows[value.rowIndex][value.cellIndex].value.data =
            value.ocrData;
        } else {
          this.formValues[value.key_text] = value.ocrData;
        }
      }
      this.fieldData = null;
      this.startImageUpload = false;
    },
    addCapturedImageData(value) {
      this.loading = true;
      if (value) {
        if (value.type === "table") {
          this.tableJsonForm.rows[value.rowIndex][value.cellIndex].value.data =
            value.imageData;
        } else {
          // console.log(value);
          this.formValues[value.key_text] = value.imageData;
        }
      }
      this.fieldData = null;
      this.startImageCapturing = false;
      // this.loading = false;
      setTimeout(() => {
        this.loading = false;
      }, 2000); // 5 seconds
    },
    formInit() {
      this.formFields.forEach((field) => {
        if (field.entry_type === "check") {
          this.formValues[field.key_text] = false;
        } else if (field.entry_type === "image") {
          this.formValues[field.key_text] = null;
        } else {
          this.formValues[field.key_text] = "";
        }
      });
      if (this.checklist.submitted_timestamp) {
        this.getChecklistData();
      }
    },
    async getImageData(path) {
      this.loading = true;
      try {
        let response = await axios.post(
          this.$store.state.api + "getImageFromCloud",
          {
            filepath: path,
          },
          {
            responseType: "arraybuffer",
            headers: { Authorization: "Bearer " + this.$store.state.jwt },
          }
        );
        const base64String = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        let imageData = `data:image/png;base64,${base64String}`;
        this.loading = false;
        return imageData;
      } catch (err) {
        console.error(err);
        this.showDismissibleAlert = true;
        this.info = err;
        this.loading = false;
      }
    },
    getChecklistData() {
      this.loading = true;
      this.checklistFormDetails = [];
      axios
        .post(
          this.$store.state.api + "getChecklistDataById",
          {
            checklist_id: this.checklist.checklist_id,
            timestamp: this.checklist.epoch_timestamp,
          },
          { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
        )
        .then(async (response) => {
          if (response.data.status === "success") {
            // this.checklistFormDetails = [...response.data.data];
            let dbData = [...response.data.data];
            let temp = [];
            for (let i of dbData) {
              if (i.entry_type === "image" && i.data) {
                if (i.data.startsWith("assets")) {
                  let imageData = await this.getImageData(i.data);
                  temp.push({
                    key_text: i.key_text,
                    entry_type: i.entry_type,
                    data: i.data,
                    imageData: imageData,
                  });
                } else {
                  temp.push({
                    key_text: i.key_text,
                    entry_type: i.entry_type,
                    data: i.data,
                    imageData: i.data,
                  });
                }
              } else {
                temp.push({
                  key_text: i.key_text,
                  entry_type: i.entry_type,
                  data: i.data,
                });
              }
            }
            this.checklistFormDetails = temp;
            for (let i of response.data.data) {
              for (let j of this.formFields) {
                if (i.key_text === j.key_text) {
                  if (j.entry_type === "check") {
                    i.data === "true"
                      ? (this.formValues[j.key_text] = true)
                      : (this.formValues[j.key_text] = false);
                  } else {
                    this.formValues[j.key_text] = i.data;
                  }
                }
              }
            }
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    getChecklistTableJsonForm() {
      this.loading = true;
      axios
        .post(
          this.$store.state.api + "checklistTemplateDetails",
          { template_id: this.checklist.template_id },
          { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
        )
        .then((response) => {
          if (response.data.status === "success") {
            if (response.data.data[0].table_json) {
              this.tableJsonForm = response.data.data[0].table_json;
            }
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    getChecklistTemplate() {
      this.loading = true;
      this.formFields = [];
      axios
        .post(
          this.$store.state.api + "checklistTemplateMetaById",
          { template_id: this.checklist.template_id },
          { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.formFields = [...response.data.data];
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    // convertToBase64(keyText) {
    //     const file = this.fieldFiles[keyText];
    //     if (file) {
    //         const reader = new FileReader();

    //         reader.onload = (e) => {
    //         this.$set(this.base64Images, keyText, e.target.result); // Set Base64 value dynamically
    //         };

    //         reader.onerror = (error) => {
    //         console.error(`Error converting file for ${keyText} to Base64:`, error);
    //         };

    //         reader.readAsDataURL(file);
    //     } else {
    //         this.$delete(this.base64Images, keyText); // Remove Base64 value if no file
    //     }
    // },
    validateAndCompressImage(keyText) {
      const file = this.fieldFiles[keyText];
      if (file) {
        // Check file size
        if (file.size > this.maxFileSize) {
          alert(`File size exceeds ${this.maxFileSize / 1024} KB limit`);
          this.$delete(this.fieldFiles, keyText);
          this.$delete(this.base64Images, keyText);
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          this.compressImage(e.target.result, keyText);
        };

        reader.onerror = (error) => {
          console.error(`Error reading file for ${keyText}:`, error);
        };

        reader.readAsDataURL(file);
      } else {
        this.$delete(this.base64Images, keyText);
      }
    },
    compressImage(imageSrc, keyText) {
      const img = new Image();
      img.src = imageSrc;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the target dimensions
        let width = img.width;
        let height = img.height;

        // Scale down if necessary
        if (width > this.maxImageWidth || height > this.maxImageHeight) {
          const aspectRatio = width / height;
          if (width > height) {
            width = this.maxImageWidth;
            height = width / aspectRatio;
          } else {
            height = this.maxImageHeight;
            width = height * aspectRatio;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        // Compress the image and save the Base64
        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.8); // 80% quality
        this.$set(this.base64Images, keyText, compressedBase64);
      };

      img.onerror = (error) => {
        console.error(`Error loading image for ${keyText}:`, error);
      };
    },
    startChecklistFilling() {
      let payload = {
        checklist_id: this.checklist.checklist_id,
        timestamp: Number(this.checklist.epoch_timestamp),
      };
      axios
        .post(this.$store.state.api + "startChecklistFill", payload, {
          headers: { Authorization: "Bearer " + this.$store.state.jwt },
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.$emit("submitted");
            this.info = "You can start filling the checklist";
            this.showDismissibleAlert = true;
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    submitForm(submit) {
      if (this.$refs.dynamicForm.validate()) {
        this.loading = true;

        let hasImage = this.formFields.some(
          (field) =>
            field.entry_type === "image" && this.formValues[field.key_text]
        );
        let payload = hasImage
          ? new FormData()
          : {
              checklist_id: this.checklist.checklist_id,
              timestamp: Number(this.checklist.epoch_timestamp),
              checklist_meta: {},
              submit,
              hasImage: false,
            };

        let temp = {};
        if (hasImage) {
          payload.append("checklist_id", this.checklist.checklist_id);
          payload.append("timestamp", Number(this.checklist.epoch_timestamp));
          payload.append("submit", submit);
          payload.append("hasImage", true);
        }
        this.formFields.forEach((field) => {
          let key = field.key_text;
          let value = this.formValues[key];
          temp[key] = value;
        });
        if (hasImage) {
          payload.append("checklist_meta", JSON.stringify(temp));
        } else {
          payload["checklist_meta"] = temp;
        }

        if (this.tableJsonForm) {
          if (hasImage) {
            // payload.append("table_json", this.tableJsonForm);
            payload.append("table_json", JSON.stringify(this.tableJsonForm));
          }
          payload["table_json"] = this.tableJsonForm;
        }

        let endpoint =
          this.checklist && this.checklist.status === "refill"
            ? "refillChecklistData"
            : "writeChecklistData";

        let config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
            ...(hasImage && { "Content-Type": "multipart/form-data" }), // Only set multipart headers if needed
          },
        };
        axios
          .post(this.$store.state.api + endpoint, payload, config)
          .then((response) => {
            this.loading = false;
            if (response.data.status === "success") {
              this.$emit("submitted");
              this.info = "Checklist submitted successfully!";
            } else {
              this.info = response.data.msg;
            }
            this.showDismissibleAlert = true;
          })
          .catch((err) => {
            console.error(err);
            this.showDismissibleAlert = true;
            this.info = err;
            this.loading = false;
          });
      }
    },

    // Utility function to convert base64 to File
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    // submitForm(submit) {
    //   if (this.$refs.dynamicForm.validate()) {
    //     this.loading = true;
    //     let payload = {
    //       checklist_id: this.checklist.checklist_id,
    //       timestamp: Number(this.checklist.epoch_timestamp),
    //       checklist_meta: {},
    //       submit,
    //     };
    //     let temp = {};
    //     this.formFields.map((field) => {
    //       temp[field.key_text] = this.formValues[field.key_text];
    //       // if (field.entry_type === "image" && this.base64Images) {
    //       //   temp[field.key_text] = this.base64Images[field.key_text] || null;
    //       // } else {
    //       //   temp[field.key_text] = this.formValues[field.key_text];
    //       // }
    //     });
    //     payload["checklist_meta"] = temp;
    //     let endpoint = "writeChecklistData";
    //     if (this.tableJsonForm) {
    //       payload["table_json"] = this.tableJsonForm;
    //     }
    //     if (this.checklist && this.checklist.status === "refill") {
    //       endpoint = "refillChecklistData";
    //     }
    //     axios
    //       .post(this.$store.state.api + endpoint, payload, {
    //         headers: { Authorization: "Bearer " + this.$store.state.jwt },
    //       })
    //       .then((response) => {
    //         if (response.data.status === "success") {
    //           this.$emit("submitted");
    //           this.info = "Checklist submitted successfully!";
    //           this.showDismissibleAlert = true;
    //           this.loading = false;
    //         } else {
    //           this.info = response.data.msg;
    //           this.showDismissibleAlert = true;
    //           this.loading = false;
    //         }
    //         this.loading = false;
    //       })
    //       .catch((err) => {
    //         console.error(err);
    //         this.showDismissibleAlert = true;
    //         this.info = err;
    //         this.loading = false;
    //       });
    //   }
    // },
  },
  watch: {
    // checklist: {
    //     handler() {
    //         if (this.checklist && this.checklist.checklist_id && this.checklist.template_id) {
    //             this.getChecklistTemplate()
    //         }
    //     },
    //     deep:true
    // },
    formFields: {
      handler() {
        if (this.formFields && this.formFields.length > 0) {
          this.formInit();
        }
      },
      deep: true,
    },
  },
};
</script>
