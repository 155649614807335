<template>
  <v-container fluid class="no-padding">
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-card
      v-if="!loading"
      class="pa-5"
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().background2ColorCode"
      flat
    >
      <div class="d-flex justify-space-between">
        <h3 class="py-4">
          {{ $store.getters.getTextMap().create }}
          {{ $store.getters.getTextMap().log_book }}
          {{ $store.getters.getTextMap().template }}
        </h3>
        <div>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            @click="close"
            class="mr-3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col
            ><v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-text-field
              v-if="!loading"
              :label="$store.getters.getTextMap().name"
              :placeholder="$store.getters.getTextMap().name"
              v-model="form.name"
              :rules="[rules.required]"
              outlined
              dense
            >
            </v-text-field
          ></v-col>
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-text-field
              v-if="!loading"
              :label="$store.getters.getTextMap().description"
              :placeholder="$store.getters.getTextMap().description"
              v-model="form.msg"
              outlined
              dense
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" sm="6" md="6">
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-text-field
              v-if="!loading"
              :label="$store.getters.getTextMap().version_no"
              placeholder="Version Number"
              v-model="form.version_no"
              outlined
              dense
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-menu
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              v-if="!loading"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :dark="$store.getters.getColorPalette().isDark"
                  :color="$store.getters.getColorPalette().inputtextColor"
                  v-model="form.version_date"
                  :label="$store.getters.getTextMap().version_date"
                  outlined
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.version_date"
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" sm="6" md="6">
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-text-field
              v-if="!loading"
              :label="$store.getters.getTextMap().issue_no"
              placeholder="Issue No"
              v-model="form.issue_no"
              outlined
              dense
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-menu
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              v-if="!loading"
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :dark="$store.getters.getColorPalette().isDark"
                  :color="$store.getters.getColorPalette().inputtextColor"
                  v-model="form.issue_date"
                  :label="$store.getters.getTextMap().issue_date"
                  outlined
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.issue_date"
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-text-field
              v-if="!loading"
              :label="$store.getters.getTextMap().document_no"
              :placeholder="$store.getters.getTextMap().document_no"
              v-model="form.doc_no"
              outlined
              dense
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider class="divider"></v-divider>
        <v-card
          outlined
          class="px-3 py-2"
          :color="$store.getters.getColorPalette().background2ColorCode"
        >
          <div class="d-flex justify-space-between">
            <h3 class="pt-4 pb-2">
              {{ $store.getters.getTextMap().add }}
              {{ $store.getters.getTextMap().table }}
            </h3>
          </div>
          <v-row v-if="!loading" no-gutters class="pb-2">
            <v-col>
              <ManufacturingLogbookTableForm
                :table_details="table_details"
                :editable="false"
                v-on:value="addJsonToMeta"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-divider class="divider"></v-divider>
        <!-- Table Display -->
        <v-row
          v-for="(item, index) in form.manufacturing_logbook_template_meta"
          :key="index"
          class="mb-1"
          no-gutters
        >
          <v-col cols="12">
            <v-card
              outlined
              :color="$store.getters.getColorPalette().background2ColorCode"
            >
              <v-card-title class="d-flex justify-space-between">
                <span>{{ item.table_key }} ({{ item.table_type }})</span>
                <v-btn
                  icon
                  :color="$store.getters.getColorPalette().deletebtnColor"
                  @click="deleteMeta(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="!loading">
                <ManufacturingLogbookTableForm
                  :table_details="item"
                  :editable="editable"
                  v-on:update="(data) => updateJsonToMeta(data, index)"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="divider"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center pa-8">
            <v-btn
              :color="$store.getters.getColorPalette().submitbtnColor"
              @click="onSubmit"
              class="mr-3"
            >
              {{ $store.getters.getTextMap().submit }}
            </v-btn>
            <v-btn
              :color="$store.getters.getColorPalette().cancelbtnColor"
              :dark="$store.getters.getColorPalette().isDark"
              @click="$emit('closeForm')"
              class="ml-3"
            >
              {{ $store.getters.getTextMap().cancel }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import FormRules from "@/utillities/FormRules";
import InfoAlert from "@/components/InfoAlert";
import axios from "axios";
import ManufacturingLogbookTableForm from "@/components/forms/manufacturing/logbook/ManufacturingLogbookTableForm.vue";
export default {
  name: "CreateManufacturingLogbookTemplate",
  props: ["editTemplateData"],
  components: {
    InfoAlert,
    ManufacturingLogbookTableForm,
  },
  data() {
    return {
      showDismissibleAlert: false,
      info: "",
      loading: false,
      valid: false,
      rules: FormRules.rules,
      menu: null,
      menu1: null,
      editable: false,
      endpoint: "createManufacturingLogbookTemplate",
      table_details: {},
      form: {
        name: null,
        msg: null,
        doc_no: null,
        version_no: null,
        version_date: null,
        issue_no: null,
        issue_date: null,
        manufacturing_logbook_template_meta: [],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.editable = false;
      this.form = {
        name: null,
        msg: null,
        doc_no: null,
        version_no: null,
        version_date: null,
        issue_no: null,
        issue_date: null,
        manufacturing_logbook_template_meta: [],
      };
      if (this.editTemplateData && this.editTemplateData.template_id) {
        this.form = this.editTemplateData;
        this.editable = true;
        this.getTemplateData();
      }
    },
    close() {
      this.loading = true;
      this.editable = false;
      this.$emit("closeForm");
      this.loading = false;
    },
    deleteMeta(index) {
      this.loading = true;
      this.form.manufacturing_logbook_template_meta.splice(index, 1);
      this.loading = false;
    },
    generateHeaders(headersArray) {
      if (!headersArray || headersArray.length === 0) {
        return [{ text: "No Headers", value: "empty" }];
      }
      return headersArray.map((header) => ({
        text: header,
        value: header,
      }));
    },
    addJsonToMeta(value) {
      this.loading = true;
      this.form.manufacturing_logbook_template_meta.push({ ...value });
      this.loading = false;
    },
    updateJsonToMeta(value, main_index) {
      this.loading = true;
      this.form.manufacturing_logbook_template_meta[main_index].table_key =
        value.table_key;
      this.form.manufacturing_logbook_template_meta[main_index].table_type =
        value.table_type;
      this.form.manufacturing_logbook_template_meta[main_index].data_meta =
        value.data_meta;
      this.info = "Table updated. Make sure to save the changes";
      this.showDismissibleAlert = true;
      this.loading = false;
    },
    getTemplateData() {
      this.loading = true;
      this.templateMeta = [];
      axios
        .post(
          this.$store.state.api + "manufacturingLogbookTemplateMetaById",
          { template_id: this.editTemplateData.template_id },
          { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.form.manufacturing_logbook_template_meta = [
              ...response.data.data,
            ];
            this.loading = false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showDismissibleAlert = true;
          this.info = err;
          this.loading = false;
        });
    },
    onSubmit() {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.editable) {
          this.form["update_template"] = true;
        }
        axios
          .post(this.$store.state.api + this.endpoint, this.form, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              this.$emit("refetchData");
              this.$emit("closeForm");
              this.loading = false;
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
              this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.info = err;
            this.loading = false;
          });
      }
    },
  },
  watch: {
    editTemplateData: {
      handler(newVal, oldVal) {
        if (!oldVal || newVal.template_id !== oldVal.template_id) {
          this.init(); // Only reset when the template ID actually changes
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.divider {
  border-top: 4px solid rgba(255, 255, 255, 0.2);
}
</style>
