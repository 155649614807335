var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"no-padding bordered-container",attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert = false}}}),(!_vm.loading)?_c('v-card',{staticClass:"pa-5",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().background2ColorCode,"flat":""}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h3',{staticClass:"py-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().create)+" "+_vm._s(_vm.$store.getters.getTextMap().reference)+" "+_vm._s(_vm.$store.getters.getTextMap().sheet)+" ")]),_c('div',[_c('v-btn',{staticClass:"mr-3",attrs:{"color":_vm.$store.getters.getColorPalette().submitbtnColor},on:{"click":function($event){return _vm.$emit('closeForm')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-select',{attrs:{"label":"Select columns from list","placeholder":"Select columns from list","items":_vm.lists,"item-text":"label","item-value":"value","outlined":"","dense":""},model:{value:(_vm.list_id),callback:function ($$v) {_vm.list_id=$$v},expression:"list_id"}}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-2"},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('v-switch',{staticStyle:{"transform":"scale(0.8)"},attrs:{"small":"","inset":"","color":"success","label":_vm.$store.getters.getTextMap().new +
                ' ' +
                _vm.$store.getters.getTextMap().table},model:{value:(_vm.newTable),callback:function ($$v) {_vm.newTable=$$v},expression:"newTable"}}),(!_vm.loading && _vm.newTable)?_c('v-text-field',{attrs:{"placeholder":"Name","label":"Name","rules":[_vm.rules.required],"outlined":"","dense":""},model:{value:(_vm.form.table_name),callback:function ($$v) {_vm.$set(_vm.form, "table_name", $$v)},expression:"form.table_name"}}):_vm._e(),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(!_vm.loading && !_vm.newTable)?_c('v-select',{attrs:{"label":"Select existing table","placeholder":"Select existing table","items":_vm.existingSheets,"item-text":"table_name","item-value":"table_name","outlined":"","dense":""},model:{value:(_vm.form.table_name),callback:function ($$v) {_vm.$set(_vm.form, "table_name", $$v)},expression:"form.table_name"}}):_vm._e()],1)],1),_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-text-field',{attrs:{"label":"Key Value","placeholder":"Key Value","rules":[_vm.rules.required],"outlined":"","dense":""},model:{value:(_vm.form.key_value),callback:function ($$v) {_vm.$set(_vm.form, "key_value", $$v)},expression:"form.key_value"}}):_vm._e()],1)],1),(
          _vm.form &&
          _vm.form.meta &&
          Object.keys(_vm.form.meta) &&
          Object.keys(_vm.form.meta).length > 0
        )?_c('div',_vm._l((Object.keys(_vm.form.meta)),function(item,index){return _c('v-row',{key:index,attrs:{"no-gutters":""}},[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}}):_vm._e(),(!_vm.loading)?_c('v-text-field',{attrs:{"label":item,"placeholder":item,"rules":[_vm.rules.required],"outlined":"","dense":""},model:{value:(_vm.form.meta[item]),callback:function ($$v) {_vm.$set(_vm.form.meta, item, $$v)},expression:"form.meta[item]"}}):_vm._e()],1)],1)}),1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center pa-8",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":_vm.$store.getters.getColorPalette().submitbtnColor},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().add)+" ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":_vm.$store.getters.getColorPalette().cancelbtnColor,"dark":_vm.$store.getters.getColorPalette().isDark},on:{"click":function($event){return _vm.$emit('closeForm')}}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().cancel)+" ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }