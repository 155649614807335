<template>
  <v-container
    fluid
    :style="{
      'background-color': $store.getters.getColorPalette().backgroundColorCode,
      color: $store.getters.getColorPalette().accentCode,
    }"
  >
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />

    <v-card :dark="$store.getters.getColorPalette().isDark">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$store.getters.getTextMap().search"
          single-line
          hide-details
          :dark="$store.getters.getColorPalette().isDark"
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="modbusParamData"
        item-key="param_id"
        :search="search"
        :single-expand="true"
        show-expand
        :expanded.sync="expanded"
        :loading="loading"
        :dark="$store.getters.getColorPalette().isDark"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{$store.getters.getTextMap().param_view}}
</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <span>
              <v-icon  :color="$store.getters.getColorPalette().submitbtnColor" :dark="$store.getters.getColorPalette().isDark"  :style="{fontSize:'25px'}" @click="downloadCsv">mdi-download-circle</v-icon>
            </span>
            <v-icon @click="getData">mdi-refresh</v-icon>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <EditModbusParameter :parameter="item" v-on:update="getData()" />
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import InfoAlert from "@/components/InfoAlert";
import EditModbusParameter from "@/components/crud_components/EditModbusParameter";

export default {
  name: "ModbusParamViewTable",
  components: {
    InfoAlert,
    EditModbusParameter,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      modbusParamData: [],
      search: "",
      info: "",
      showDismissibleAlert: false,
      loading: false,
      expanded: [],
      headers: [
        {
          text: "Workspace Name",
          value: "workspace_name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Device Name",
          value: "device_name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Param Name",
          value: "name",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Hub ID",
          value: "hub_id",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Byte Format",
          value: "byte_format",
          class:
          " text-capitalize font-weight-black " +
          this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Unit ID",
          value: "unit_id",
          class:
          " text-capitalize font-weight-black " +
          this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Address",
          value: "address",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Offset",
          value: "ofst",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Correction Factor",
          value: "correction_factor",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Register Size",
          value: "register_size",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Val Type",
          value: "val_type",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Processing",
          value: "processing",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Param Type",
          value: "param_type",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Aliasing",
          value: "aliasing",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Meta",
          value: "meta",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Writable",
          value: "writable",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Engineer Access Only",
          value: "engineer_access_only",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Remove Zero",
          value: "rem_zero",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Remove negative entropy",
          value: "rem_negative_entropy",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Disabled",
          value: "disabled",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Unit",
          value: "unit",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Make",
          value: "make",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Model",
          value: "model",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Created",
          value: "created",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "param ID",
          align: "start",
          filterable: true,
          value: "param_id",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "device ID",
          align: "start",
          filterable: false,
          value: "device_id",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
        {
          text: "Workspace ID",
          align: "start",
          filterable: false,
          value: "workspace_id",
          class:
            " text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        },
      ],
    };
  },
  computed: {},
  methods: {

    downloadCsv(){    
            let csvContent = 'workspace_name,device_name,name,hub_id,byte_format,unit_id,address,ofst,correction_factor,register_size,val_type,processing,param_type,aliasing,meta,writable,engineer_access_only,rem_zero,rem_negative_entropy,disabled,unit,make,model,created,param_id,device_id,workspace_id\n '+ this.modbusParamData.map((obj)=>`'${obj.workspace_name},${obj.device_name},${obj.name},${obj.hub_id},${obj.byte_format},${obj.unit_id},${obj.address},${obj.ofst},${obj.correction_factor},${obj.register_size},${obj.val_type},${obj.processing},${obj.param_type},${obj.aliasing},${obj.meta},${obj.writable},${obj.engineer_access_only},${obj.rem_zero},${obj.rem_negative_entropy},${obj.disabled},${obj.unit},${obj.make},${obj.model},${obj.created},"${obj.param_id}",${obj.device_id},${obj.workspace_id}`).join("\n")
            const blob = new Blob([csvContent], {type:'text/csv'})
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'ModbusParamViewTableData.csv'
            document.body.appendChild(link);
            link.click()
            document.body.removeChild(link)
    },
  
    getData() {
      axios
        .get(this.$store.state.api + "modbusParams", {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
  
          if (response.data.status == "success") {
            //console.log(response.data);

            this.modbusParamData = response.data.data;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch((error) => {
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.v-card__title {
  background-color: #bdbdbd00;
}
.theme--light.v-toolbar.v-sheet {
  background-color: #b2dfdb00;
}
.theme--light.v-data-table {
  background-color: #b2dfdb00;
}
</style>
