<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-card :dark="$store.getters.getColorPalette().isDark">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$store.getters.getTextMap().search"
            single-line
            hide-details
            :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="machineParamData"
          item-key="param_id"
          :search="search"


          :loading="loading"
         :dark="$store.getters.getColorPalette().isDark"
        loading-text="Loading... Please wait"
        >
        <template v-slot:top>
          <v-toolbar
            flat
            
          >
            <v-toolbar-title>{{$store.getters.getTextMap().param_view}}
</v-toolbar-title>
            
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <span>
              <v-icon  :color="$store.getters.getColorPalette().submitbtnColor" :dark="$store.getters.getColorPalette().isDark"  :style="{fontSize:'25px'}" @click="downloadCsv">mdi-download-circle</v-icon>
            </span>
            <v-icon @click="getData">mdi-refresh</v-icon>
            
          </v-toolbar>
              </template>
              
              
        </v-data-table>
      </v-card>
    </v-container>
    </template>
    
    <script>
    import axios from 'axios'
    import InfoAlert from '@/components/InfoAlert'
    
    export default {
        name:'MachineParamViewTable',
        components:{
            InfoAlert
        },
        mounted(){
            this.getData()
        },
        data(){
            return {
                machineParamData:[],
                search:'',
                info:'',
                showDismissibleAlert:false,
                loading:false,
                expanded: [],
                headers: [
              
              { text: 'Workspace Name', value: 'workspace_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Process Name', value: 'process_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Machine Name', value: 'machine_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Param Name', value: 'param_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Tag Type', value: 'tag_type', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Processing', value: 'processing', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Param Type', value: 'param_type', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Writable', value: 'writable', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Engineer Access Only', value: 'engineer_access_only', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Remove Zero', value: 'rem_zero', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Remove negative entropy', value: 'rem_negative_entropy', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Disabled', value: 'disabled', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Unit', value: 'unit', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Created', value: 'created', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              {
                text: 'Hub ID',
                value: 'hub_id',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                
              },
              {
                text: 'param ID',
                align: 'start',
                filterable: true,
                value: 'param_id',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                
              },
              {
                text: 'machine ID',
                align: 'start',
                filterable: false,
                value: 'machine_id',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                
              },
              {
                text: 'Workspace ID',
                align: 'start',
                filterable: false,
                value: 'workspace_id',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                
              },
          
              
            ],
            }
        },
        computed:{
            
        },
        methods:{

          downloadCsv(){
            let csvContent = 'workspace_name, process_name, machine_name, param_name,tag_type,processing,param_type,writable,engineer_access_only,rem_zero,rem_negative_entropy,disabled,unit,created,hub_id,param_id,machine_id,workspace_id\n' + this.machineParamData.map((obj)=>{
             return  `${obj.workspace_name},${obj.process_name},${obj.machine_name},${obj.param_name},${obj.tag_type},${obj.processing},${obj.param_type},${obj.writable},${obj.engineer_access_only},${obj.rem_zero},${obj.rem_negative_entropy},${obj.disabled},${obj.unit},${obj.created},${obj.hub_id},"${obj.param_id}",${obj.machine_id},${obj.workspace_id}`
            }).join("\n")
            const blob = new Blob([csvContent],{type:'text/csv'});

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "MachineParamViewTableData.csv"
            document.body.appendChild(link);
            link.click()
            document.body.removeChild(link)

          },
            getData(){
                axios.get(this.$store.state.api + "machineParams",{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    
                    
                    //console.log(response.data);
                    
                    this.machineParamData=response.data.data
                
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
        },
    }
    </script>
    <style scoped>
    .v-card__title{
      background-color:#bdbdbd00;
    }
    .theme--light.v-toolbar.v-sheet{
      background-color: #B2DFDB00;
    }
    .theme--light.v-data-table{
       background-color: #B2DFDB00;
    }
    </style>